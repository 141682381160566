import styled, { css } from "styled-components";
import theme from "./theme";
import { motion } from "framer-motion";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: calc(100vw - 380px);
  margin: 50px 50px 50px 330px;
  min-height: 80vh;
  height: auto;

  padding-bottom: 40px;

  #sections-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding-right: 24px;
    min-height: 75vh;
    height: auto;
  }

  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .header-text {
      display: flex;
      flex-direction: column;
      h1 {
        margin-bottom: 0;
      }
    }

    .channel-dropdown {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    #buttons {
      display: flex;
      gap: 12px;
    }
  }

  .cache-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  #connect-channels {
    display: flex;
    gap: 12px;
  }

  ${({ $isSignUp }) =>
    $isSignUp &&
    css`
      margin: 100px auto;
      align-items: center;

      .header-text {
        h1 {
          font-size: 40px;
        }

        h3 {
          text-align: center;
        }
      }
    `}

  ${({ $noScroll }) =>
    $noScroll &&
    css`
      overflow-y: hidden;
      margin: 50px 50px 0 330px;
      padding-bottom: 0;
      height: calc(100vh - 100px);
    `}
`;

export const H1 = styled.h1`
  color: ${theme.color.black};
  font-family: ${theme.font.fontFamilyPrimary}, sans-serif;
  font-size: ${theme.font.size.xl};

  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const H2 = styled.h2`
  font-size: ${theme.font.size.lg};
  font-weight: 600;
  color: ${theme.textPrimary};
  margin-bottom: 20px;
  font-family: ${theme.font.fontFamilyPrimary}, sans-serif;
`;

export const H3 = styled.h3`
  font-size: ${theme.font.size.base};
  font-weight: 500;
  color: ${theme.textPrimary};
  margin-bottom: 16px;
  font-family: ${theme.font.fontFamilyPrimary}, sans-serif;
`;

export const H4 = styled.h4`
  font-size: ${theme.font.size.sm};
  font-weight: 500;
  color: ${theme.color.gray[600]};
  margin-bottom: 12px;
  margin-top: 8px;
  font-family: ${theme.font.fontFamilyPrimary}, sans-serif;
`;

export const NavButton = styled.button`
  display: flex;
  padding: 12px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 46px;
  border: none;
  background: #f3f4f6;
  color: var(--Black, #0a0b1f);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 48px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: #e8eaeef0;
  }

  ${({ $isFilled }) =>
    $isFilled &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};

      &:hover {
        background: ${theme.color.primaryLight};
      }
    `}

  ${({ $isStats }) =>
    $isStats &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};

      &:hover {
        background: ${theme.color.primaryLight};
      }

      padding: 12px 16px;
    `}

  ${({ $isCopy }) =>
    $isCopy &&
    css`
      position: absolute;
      bottom: 8px;
      right: 8px;
    `}

  ${({ $isOutlined }) =>
    $isOutlined &&
    css`
      border: 1.5px solid ${theme.color.primary};
      background: none;
      color: ${theme.color.primary};
      padding: 12px 16px;
    `}
`;

export const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: none;
  color: ${theme.color.black};
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0;

  cursor: pointer;

  option {
    color: ${theme.color.black};
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: none;
    border: none;
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    border: none;
  }
`;
export const SearchInput = styled.input`
  width: 300px;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const ComingSoonContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 60vh;
  gap: 24px;
`;

export const ComingSoonIllustration = styled(motion.div)`
  font-size: 64px;
  margin-bottom: 24px;
`;

export const ComingSoonDescription = styled(motion.p)`
  color: ${theme.color.gray[700]};
  font-size: 18px;
  max-width: 600px;
  margin: 0;
  line-height: 1.6;
`;
