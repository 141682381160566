import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setUserDocument } from "../store/slices/userSlice";

// Import assets
import instagramLogo from "../assets/images/ig-logo.png";
import facebookLogo from "../assets/images/fb-logo.png";
import gmailLogo from "../assets/images/gmail-logo.png";
import shopifyLogo from "../assets/images/shopifyLogo.png";
// Import styled components
import {
  Container,
  Title,
  ChannelsGrid,
  ChannelItem,
  ConnectionStatus,
  ChannelIcon,
  ChannelButton,
  BackButton,
  ErrorMessage,
  ConnectChannelsContainer,
  ShopifyInputContainer,
  StyledInput,
  ButtonContainer,
  ModalOverlay,
  ModalText,
} from "./styles/ConnectChannels.styled";
import ConnectChannelBlock from "../components/ConnectChannelBlock";
import { MainContainer, H1, NavButton } from "./styles/global/main.style";
import { useLocation } from "react-router-dom";

const Channels = [
  {
    img_url: facebookLogo,
    name: "Facebook",
    description: "Connect your Facebook page to start sending messages.",
    isOutlined: true,
  },
  {
    img_url: instagramLogo,
    name: "Instagram",
    description: "Connect your Instagram account to start sending messages.",
    isOutlined: false,
  },
  {
    img_url: gmailLogo,
    name: "Gmail",
    description: "Connect your Gmail account to start sending messages.",
    isOutlined: false,
  },
  {
    img_url: shopifyLogo,
    name: "Shopify",
    description: "Connect your Shopify store to start optimizing",
    isOutlined: false,
  },
];

const ConnectChannels = () => {
  const dispatch = useDispatch();
  const { userDocument } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const location = useLocation();
  const connectedChannels = useSelector(
    (state) => state.user.userDocument?.connectedChannels || []
  );
  const [showShopifyInput, setShowShopifyInput] = useState(false);
  const [showReconnectConfirm, setShowReconnectConfirm] = useState(false);
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [loadingPlatform, setLoadingPlatform] = useState(null);

  useEffect(() => {
    // Check URL parameters
    const params = new URLSearchParams(window.location.search);
    if (params.get("facebook") === "success") {
      setIsRedirecting(false);
    }
  }, []);

  if (isRedirecting) {
    return <div>Connecting to Facebook...</div>;
  }

  const handleInstagramConnect = async () => {
    setLoading(true);
    setError("");
    setInstagramError("");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/instagram/connect`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const updatedChannels = new Set([
          ...(userDocument.connectedChannels || []),
          "instagram",
        ]);

        dispatch(
          setUserDocument({
            ...userDocument,
            instagramBusinessAccountId: response.data.instagramAccount.id,
            instagramUsername: response.data.instagramAccount.username,
            connectedChannels: Array.from(updatedChannels),
          })
        );
      }
    } catch (error) {
      console.error("Instagram connection error:", error);
      if (error.response?.data?.needsFacebookAuth) {
        setInstagramError("Please connect Facebook first");
      } else if (error.response?.data?.needsInstagramBusinessAccount) {
        setInstagramError(
          "No Instagram business account found. Please convert your Instagram account to a business account and try again."
        );
      } else {
        setInstagramError(
          error.response?.data?.error || "Failed to connect Instagram"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleShopifySubmit = async () => {
    if (!shopifyUrl) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/shopify/connect`,
        {
          params: { shop: shopifyUrl },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.authUrl) {
        window.location.href = response.data.authUrl;
      }
    } catch (error) {
      setError(error.response?.data?.error || "Failed to connect to Shopify");
    } finally {
      setShowShopifyInput(false);
      setShopifyUrl("");
    }
  };

  const handleConnect = async (platform) => {
    setSelectedPlatform(platform);
    const platformLower = platform.toLowerCase();
    setLoadingPlatform(platformLower);

    if (platformLower === "shopify") {
      if (connectedChannels.includes("shopify")) {
        setShowReconnectConfirm(true);
        setLoadingPlatform(null);
        return;
      }
      setShowShopifyInput(true);
      setLoadingPlatform(null);
      return;
    }

    setLoading(true);
    setError("");

    try {
      if (platformLower === "facebook") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/facebook/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "gmail") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/gmail/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "instagram") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/instagram/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          const updatedChannels = new Set([
            ...(userDocument.connectedChannels || []),
            "instagram",
          ]);

          dispatch(
            setUserDocument({
              ...userDocument,
              instagramBusinessAccountId: response.data.instagramAccount.id,
              instagramUsername: response.data.instagramAccount.username,
              connectedChannels: Array.from(updatedChannels),
            })
          );
        }
      }
    } catch (error) {
      setError(
        error.response?.data?.error || `Failed to connect to ${platform}`
      );
      console.error(`${platform} connection error:`, error);
    } finally {
      setLoading(false);
      setSelectedPlatform(null);
      setLoadingPlatform(null);
    }
  };

  return (
    <MainContainer>
      <div id="header">
        <H1>Connect Channels</H1>
      </div>
      <ConnectChannelsContainer>
        {Channels.map((channel) => (
          <ConnectChannelBlock
            key={channel.name}
            {...channel}
            isConnected={connectedChannels.includes(channel.name.toLowerCase())}
            handleConnect={handleConnect}
            isLoading={loadingPlatform === channel.name.toLowerCase()}
          />
        ))}
      </ConnectChannelsContainer>

      {showReconnectConfirm && (
        <ModalOverlay>
          <ShopifyInputContainer>
            <ModalText>
              You are already connected to a Shopify store. Would you like to
              reconnect to a different store?
            </ModalText>
            <ButtonContainer>
              <NavButton
                onClick={() => {
                  setShowReconnectConfirm(false);
                  setSelectedPlatform(null);
                }}
              >
                Cancel
              </NavButton>
              <NavButton
                $isFilled
                onClick={() => {
                  setShowReconnectConfirm(false);
                  setShowShopifyInput(true);
                }}
              >
                Reconnect
              </NavButton>
            </ButtonContainer>
          </ShopifyInputContainer>
        </ModalOverlay>
      )}

      {showShopifyInput && (
        <ModalOverlay>
          <ShopifyInputContainer>
            <StyledInput
              type="text"
              value={shopifyUrl}
              onChange={(e) => setShopifyUrl(e.target.value)}
              placeholder="your-store.myshopify.com"
            />
            <ButtonContainer>
              <NavButton
                onClick={() => {
                  setShowShopifyInput(false);
                  setShopifyUrl("");
                }}
              >
                Cancel
              </NavButton>
              <NavButton $isFilled onClick={handleShopifySubmit}>
                Connect
              </NavButton>
            </ButtonContainer>
          </ShopifyInputContainer>
        </ModalOverlay>
      )}
    </MainContainer>
  );
};

export default ConnectChannels;
