import styled from "styled-components";
import theme from "../../pages/styles/global/theme";

export const ScriptContainer = styled.div`
  background: none;
  border-radius: 8px;
`;

export const ScriptTitle = styled.h3`
  color: #333333;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
`;

export const ScriptDescription = styled.p`
  color: #666666;
  margin-bottom: 16px;
  line-height: 1.5;
  font-size: 14px;
`;

export const CodeContainer = styled.div`
  background: #f5f5f5;
  border-radius: 6px;
  position: relative;
  margin-bottom: 16px;
`;

export const Code = styled.code`
  display: block;
  white-space: pre-wrap;
  word-break: break-all;
  color: #333333;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;
  background: ${theme.color.gray[300]};
  padding: 10px;
  border-radius: 6px;
  height: 100px;
  box-sizing: border-box;
`;

export const CopyButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s;

  &:hover {
    background: #0056b3;
  }
`;
