export const PricingPlans = [
  {
    id: "starter",
    name: "Starter",
    price: 149,
    stripeId: process.env.REACT_APP_STRIPE_STARTER_PRICE_ID,
    messages: 1000,
    features: [
      "Multi-channel ChatBot & LiveChat",
      "Basic Social Media Tools",
      "3 AI Videos Monthly",
      "5 AI Pictures Monthly",
      "Basic Shop Integrations",
      "5,000 AI Credits Monthly",
      "30 Days Growth Guarantee",
    ],
  },
  {
    id: "grow",
    name: "Grow",
    price: 249,
    stripeId: process.env.REACT_APP_STRIPE_GROW_PRICE_ID,
    messages: 3000,
    features: [
      "All Starter Features",
      "15 AI Videos Monthly",
      "20 AI Pictures Monthly",
      "Single Product Ad Management",
      "Store Conversion Analysis",
      "Priority Support",
      "60 Days Growth Guarantee",
    ],
  },
  {
    id: "scale",
    name: "Scale",
    price: 499,
    stripeId: process.env.REACT_APP_STRIPE_SCALE_PRICE_ID,
    messages: 10000,
    features: [
      "All Grow Features",
      "Unlimited AI Media",
      "Multi-product Ad Management",
      "Advanced Analytics Dashboard",
      "Dedicated Account Manager",
      "Shopify Expert Support",
      "90 Days Revenue Guarantee",
    ],
  },
];
