import React, { useState } from "react";
import styled from "styled-components";
import { H2 } from "../pages/styles/global/main.style";
import theme from "../pages/styles/global/theme";

// Modal overlay styled component
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Modal content styled component
const ModalContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  max-width: 500px;
  position: relative;
`;

// Form styled components
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Input = styled.input`
  padding: 12px;
  border: 1.5px solid #e2e2e2;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;

  ${(props) =>
    props.$primary
      ? `
    background: ${theme.color.primary};
    color: white;
  `
      : `
    background: white;
    border: 1.5px solid #E2E2E2;
    color: ${theme.color.dark};
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const AddTaskModal = ({ isOpen, onClose, onAddTask }) => {
  const [taskName, setTaskName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!taskName.trim()) return;

    setLoading(true);
    try {
      await onAddTask(taskName);
      setTaskName("");
      onClose();
    } catch (error) {
      console.error("Error adding task:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <H2>Add New Task</H2>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Enter task name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            autoFocus
          />
          <ButtonContainer>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              $primary
              disabled={!taskName.trim() || loading}
            >
              {loading ? "Adding..." : "Add Task"}
            </Button>
          </ButtonContainer>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AddTaskModal;
