import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  DashboardContainer,
  Title,
  Section,
  SectionTitle,
  TaskList,
  TaskItem,
  TaskText,
  ActionButton,
  InboxItem,
  InboxIcon,
  InboxContent,
  InboxMessage,
  InboxTime,
  OverviewContainer,
  MetricList,
  MetricsContainer,
  MetricItem,
  MetricName,
  MetricValue,
  QuickLinkItem,
  QuickLinksContainer,
  DoButton,
} from "./styles/Dashboard.styled";
import {
  MainContainer,
  NavButton,
  H1,
  H3,
  H2,
  H4,
} from "./styles/global/main.style";
import { useNavigate } from "react-router-dom";
import checkCircle from "../assets/images/check-circle.svg";
import axios from "axios";
import AddTaskModal from "../components/AddTaskModal";
import StatBlock from "../components/StatBlock";

const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes cache

const Dashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const initialMount = useRef(true);
  const navigate = useNavigate();
  const { userDocument } = useSelector((state) => state.user);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [customTasks, setCustomTasks] = useState([]);

  // Add new effect to handle auto-refresh
  useEffect(() => {
    const autoRefresh = async () => {
      // Skip if already loading or no user
      if (loading || !userDocument?.uid) return;

      const cachedData = localStorage.getItem("storeMetrics");
      const shouldRefresh =
        !cachedData ||
        (() => {
          const { timestamp } = JSON.parse(cachedData);
          return Date.now() - timestamp >= CACHE_DURATION;
        })();

      if (shouldRefresh) {
        await fetchMetrics();
      }
    };

    autoRefresh();
  }, [userDocument?.uid]); // Run when user data is available

  // Update existing loadMetrics function
  useEffect(() => {
    const loadMetrics = async () => {
      if (!userDocument?.uid) {
        setLoading(false);
        return;
      }

      const cachedData = localStorage.getItem("storeMetrics");
      if (cachedData) {
        const { metrics: cachedMetrics, timestamp } = JSON.parse(cachedData);

        // Set cached data immediately to show something
        setMetrics(cachedMetrics);
        setLastFetchTime(timestamp);

        // If cache is expired, fetch new data in background
        if (Date.now() - timestamp >= CACHE_DURATION) {
          await fetchMetrics();
        }
      } else {
        // No cache exists, fetch fresh data
        await fetchMetrics();
      }
      setLoading(false);
    };

    loadMetrics();
  }, [userDocument?.uid]);

  // Update fetchMetrics to handle errors better
  const fetchMetrics = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/store/metrics`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(response.data);

      // Cache the results
      const timestamp = Date.now();
      localStorage.setItem(
        "storeMetrics",
        JSON.stringify({
          metrics: response.data,
          timestamp,
        })
      );

      setLastFetchTime(timestamp);
      setMetrics(response.data);
    } catch (error) {
      console.error("Error fetching metrics:", error);
      // Clear invalid cache if there's an error
      localStorage.removeItem("storeMetrics");
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    await fetchMetrics();
  };

  const defaultTasks = [
    {
      complete: userDocument?.connectedChannels?.includes("instagram"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Instagram",
      taskUrl: "/channels",
    },
    {
      complete: userDocument?.connectedChannels?.includes("facebook"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Facebook",
      taskUrl: "/channels",
    },
    {
      complete: userDocument?.connectedChannels?.includes("google"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Google",
      taskUrl: "/channels",
    },
    // {
    //   complete: userDocument?.scheduledPosts?.length > 0,
    //   createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
    //   taskName: "Schedule & boost your first social media post",
    //   taskUrl: "/schedule",
    // },
  ];

  // Load custom tasks on mount
  useEffect(() => {
    const loadCustomTasks = async () => {
      if (!userDocument?.uid) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/tasks`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCustomTasks(response.data.tasks);
      } catch (error) {
        console.error("Error loading custom tasks:", error);
      }
    };

    loadCustomTasks();
  }, [userDocument?.uid]);

  // Handle adding a new task
  const handleAddTask = async (taskName) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tasks/create`,
        { title: taskName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCustomTasks([...customTasks, response.data.task]);
    } catch (error) {
      console.error("Error creating task:", error);
      throw error;
    }
  };

  // Handle completing a task
  const handleCompleteTask = async (taskId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tasks/complete/${taskId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCustomTasks(
        customTasks.map((task) =>
          task.id === taskId ? { ...task, status: "completed" } : task
        )
      );
    } catch (error) {
      console.error("Error completing task:", error);
    }
  };

  // Combine default and custom tasks
  const allTasks = useMemo(() => {
    // Helper function to check if a task is older than 24 hours
    const isOlderThanOneDay = (createdAt) => {
      const taskDate = new Date(createdAt);
      const oneDayAgo = new Date();
      oneDayAgo.setDate(oneDayAgo.getDate() - 1);
      return taskDate < oneDayAgo;
    };

    return (
      [
        ...defaultTasks,
        ...customTasks.map((task) => ({
          taskName: task.title,
          complete: task.status === "completed",
          createdAt: task.createdAt,
          isCustom: true,
          id: task.id,
        })),
      ]
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        // Filter out completed tasks older than a day
        .filter((task) => !task.complete || !isOlderThanOneDay(task.createdAt))
    );
  }, [defaultTasks, customTasks]);

  // Memoize metrics calculations
  const Metrics = useMemo(
    () => ({
      mainMetrics: [
        {
          statName: "Total Revenue",
          statValue: Number(metrics?.revenue?.total || 0),
          percentageChange: Number(metrics?.revenue?.percentageChange || 0),
        },
        {
          statName: "Total Orders",
          statValue: Number(metrics?.orders?.total || 0),
          percentageChange: Number(metrics?.orders?.percentageChange || 0),
        },
        {
          statName: "Average Order Value",
          statValue: Number(metrics?.aov?.total || 0),
          percentageChange: Number(metrics?.aov?.percentageChange || 0),
        },
        {
          statName: "Total Customers",
          statValue: Number(metrics?.customers?.total || 0),
          percentageChange: Number(metrics?.customers?.percentageChange || 0),
        },
        {
          statName: "Returning Customers",
          statValue: Number(metrics?.returningCustomers?.total || 0),
          percentageChange: Number(
            metrics?.returningCustomers?.percentageChange || 0
          ),
        },
        {
          statName: "New Customers",
          statValue: Number(metrics?.newCustomers?.total || 0),
          percentageChange: Number(
            metrics?.newCustomers?.percentageChange || 0
          ),
        },
        {
          statName: "Customer Return Rate",
          statValue: Number(metrics?.customerReturnRate?.total || 0),
          percentageChange: Number(
            metrics?.customerReturnRate?.percentageChange || 0
          ),
          percent: true,
        },
        {
          statName: "Orders per Customer",
          statValue: Number(metrics?.ordersPerCustomer?.total || 0).toFixed(2),
          percentageChange: Number(
            metrics?.ordersPerCustomer?.percentageChange || 0
          ),
        },
      ],
      metrics3: [
        {
          id: 1,
          metricName: "Post on Social Media",
          url: "/social-manager/generate",
        },
        {
          id: 2,
          metricName: "Train Chatbot",
          url: "/inbox/training",
        },
        {
          id: 3,
          metricName: "Boost Post",
          url: "/social-manager/generate",
        },
      ],
      metrics4: [
        {
          id: 1,
          metricName: "New Customers This Month",
          metricValue: Number(metrics?.newCustomers?.total || 0),
        },
        {
          id: 2,
          metricName: "Customer Return Rate",
          metricValue: `${Number(
            metrics?.customerReturnRate?.total || 0
          ).toFixed(2)}%`,
        },
        {
          id: 3,
          metricName: "Orders per Customer",
          metricValue: Number(metrics?.ordersPerCustomer?.total || 0).toFixed(
            2
          ),
        },
      ],
    }),
    [metrics]
  );

  console.log(Metrics);

  // Memoize cache age calculation
  const cacheAge = useMemo(() => {
    if (!lastFetchTime) return null;
    const minutes = Math.floor((Date.now() - lastFetchTime) / (1000 * 60));
    return `${minutes}m ago`;
  }, [lastFetchTime]);

  const totalRevenue = metrics?.metrics1?.[0]?.metricValue || "0";

  // Add this calculation before the return statement
  const uncompletedTasksCount = allTasks.filter(
    (task) => !task.complete
  ).length;

  // Update the return statement to show loading state
  if (loading) {
    return (
      <MainContainer>
        <div id="header">
          <div className="header-text">
            <H1>Loading dashboard...</H1>
          </div>
        </div>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <div id="header">
        <div className="header-text">
          <H1>Hi {userDocument?.email?.split("@")[0] || "there"} 👋</H1>
          <H3>You have {uncompletedTasksCount} tasks to complete today</H3>
        </div>
        <div className="cache-info">
          {cacheAge && <H4>Last updated: {cacheAge}</H4>}
          <NavButton onClick={handleRefresh} disabled={loading}>
            {loading ? "Refreshing..." : "Refresh"}
          </NavButton>
        </div>
      </div>

      <MetricsContainer>
        <MetricList>
          <H2>Main Top Metrics</H2>
          <div className="metric-list-container">
            {Metrics.mainMetrics.map((metric, index) => (
              <StatBlock
                key={index}
                statName={metric.statName}
                statValue={metric.statValue}
                percentageChange={metric.percentageChange}
                percent={metric.percent}
              />
            ))}
          </div>
        </MetricList>
      </MetricsContainer>

      {/* <MetricsContainer>
        <QuickLinksContainer>
          <div className="quick-links-revenue">
            <H2>
              Revenue: <span>${Metrics.mainMetrics[0].statValue}</span>
            </H2>
          </div>
          <H3>Quick Links:</H3>
          {Metrics.metrics3.map((metric) => (
            <QuickLinkItem key={metric.id} onClick={() => navigate(metric.url)}>
              <MetricName>{metric.metricName}</MetricName>
              <MetricValue>{metric.metricValue}</MetricValue>
            </QuickLinkItem>
          ))}
        </QuickLinksContainer>

        <QuickLinksContainer>
          <div className="quick-links-revenue">
            <H2>Congratulations for your amazing month! 🎉</H2>
          </div>
          {Metrics.metrics4.map((metric) => (
            <QuickLinkItem $isAchievement={true} key={metric.id}>
              <MetricName>{metric.metricName}</MetricName>
              <MetricValue>{metric.metricValue}</MetricValue>
            </QuickLinkItem>
          ))}
        </QuickLinksContainer>
      </MetricsContainer> */}

      <MetricsContainer>
        <TaskList>
          <div className="task-list-header">
            <H2>Tasks Overview</H2>
            <div className="task-list-header-buttons">
              <NavButton style={{ border: "1.5px solid #E2E2E2" }}>
                View All
              </NavButton>
              <NavButton $isFilled onClick={() => setShowAddTaskModal(true)}>
                Add Task
              </NavButton>
            </div>
          </div>
          {allTasks.map((task) => (
            <div
              className="task-item-container"
              key={task.isCustom ? task.id : task.taskName}
            >
              <TaskItem>{task.taskName}</TaskItem>
              {!task.complete ? (
                <DoButton
                  onClick={() =>
                    task.isCustom
                      ? handleCompleteTask(task.id)
                      : navigate(task.taskUrl)
                  }
                >
                  {task.isCustom ? "Complete" : "Do Now"}
                </DoButton>
              ) : (
                <DoButton $isDone>
                  <img src={checkCircle} alt="check" /> Done
                </DoButton>
              )}
            </div>
          ))}
        </TaskList>
      </MetricsContainer>

      <AddTaskModal
        isOpen={showAddTaskModal}
        onClose={() => setShowAddTaskModal(false)}
        onAddTask={handleAddTask}
      />
    </MainContainer>
  );
};

export default Dashboard;
