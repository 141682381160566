import {
  MainContainer,
  H1,
  NavButton,
  H3,
  H4,
} from "../styles/global/main.style";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import StatBlock from "../../components/StatBlock";
import { auth } from "../../config/firebase";
import {
  ChannelsContainer,
  StatsContainer,
  SkeletonStatBlock,
  SkeletonChannelBlock,
  SkeletonText,
  SkeletonValue,
} from "../styles/SocialManager.styled";
import ChannelBlock from "../../components/ChannelBlock";
import instagramLogo from "../../assets/images/ig-logo.png";
import facebookLogo from "../../assets/images/fb-logo.png";
import ConnectChannelBlock from "../../components/ConnectChannelBlock";
import { setUserDocument } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import {
  listVariants,
  itemVariants,
} from "../styles/global/framer-motion-variants";
const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes cache

// Add StatsSkeleton component
const StatsSkeleton = () => (
  <>
    <StatsContainer as={motion.div} variants={listVariants}>
      {Array.from({ length: 7 }).map((_, index) => (
        <SkeletonStatBlock key={index} as={motion.div} variants={itemVariants}>
          <SkeletonText $width="40%" />
          <SkeletonValue $width="60%" />
          <SkeletonText $width="30%" />
        </SkeletonStatBlock>
      ))}
    </StatsContainer>
    <ChannelsContainer as={motion.div} variants={listVariants}>
      {Array.from({ length: 3 }).map((_, index) => (
        <SkeletonChannelBlock
          key={index}
          as={motion.div}
          variants={itemVariants}
        >
          <SkeletonText $width="50%" $height="24px" />
          <SkeletonText $width="70%" />
          <SkeletonText $width="60%" />
          <SkeletonText $width="40%" />
        </SkeletonChannelBlock>
      ))}
    </ChannelsContainer>
  </>
);

const Stats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stats, setStats] = useState(null);
  const [previousStats, setPreviousStats] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const { userDocument } = useSelector((state) => state.user);
  const initialMount = useRef(true);
  const connectedChannels = useSelector(
    (state) => state.user.userDocument?.connectedChannels || []
  );
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [showReconnectConfirm, setShowReconnectConfirm] = useState(false);
  const [showShopifyInput, setShowShopifyInput] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [connectingPlatform, setConnectingPlatform] = useState(null);

  var isConnected = connectedChannels.includes("facebook");

  const getValidToken = async () => {
    try {
      // Add a small delay to ensure Firebase is ready
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Wait for Firebase to initialize
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      const currentUser = auth.currentUser;
      if (currentUser) {
        // Force token refresh
        const token = await currentUser.getIdToken(true);
        localStorage.setItem("token", token);
        return token;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  // Load from cache on mount
  useEffect(() => {
    const loadStats = async () => {
      // Skip if not connected or no user
      if (!isConnected || !userDocument?.uid) {
        setLoading(false);
        return;
      }

      // Wait for Firebase to initialize and get token
      const token = await getValidToken();
      if (!token) {
        console.error("No valid token available");
        setLoading(false);
        return;
      }

      const cachedData = localStorage.getItem("socialStats");
      const isFirstVisit = !sessionStorage.getItem("hasVisitedStats");

      // If we have valid cache and it's not first visit, use it
      if (cachedData && !isFirstVisit) {
        const {
          stats: cachedStats,
          previousStats: cachedPrevStats,
          timestamp,
        } = JSON.parse(cachedData);
        const isCacheValid = Date.now() - timestamp < CACHE_DURATION;

        if (isCacheValid) {
          setStats(cachedStats);
          setPreviousStats(cachedPrevStats);
          setLastFetchTime(timestamp);
          setLoading(false);
          return;
        }
      }

      // Mark as visited
      if (isFirstVisit) {
        sessionStorage.setItem("hasVisitedStats", "true");
      }

      // Fetch fresh data
      await fetchStats();
    };

    loadStats();
  }, [userDocument?.uid, isConnected]);

  // Define getTrend function
  const getTrend = useMemo(
    () => (current, previous) => {
      if (!previous) return null;
      return current >= previous ? "positive" : "negative";
    },
    []
  );

  const fetchStats = async () => {
    if (!userDocument?.uid || !isConnected) return;

    setLoading(true);
    try {
      // Get fresh token before making requests
      const token = await getValidToken();
      if (!token) {
        throw new Error("No valid token available");
      }

      // Current period stats
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/social-manager/${userDocument?.uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Previous period stats
      const previousResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/social-manager/${userDocument?.uid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            period: "previous",
          },
        }
      );

      let instagramStats = {};
      if (connectedChannels.includes("instagram")) {
        try {
          const instagramResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/instagram-stats/${userDocument.uid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          instagramStats = { instagram: instagramResponse.data.stats };
        } catch (error) {
          console.error("Error fetching Instagram stats:", error);
        }
      }

      const combinedStats = {
        ...response.data,
        ...instagramStats,
      };

      // Cache the results with both current and previous stats
      const timestamp = Date.now();
      localStorage.setItem(
        "socialStats",
        JSON.stringify({
          stats: combinedStats,
          previousStats: previousResponse.data,
          timestamp,
        })
      );

      setLastFetchTime(timestamp);
      setStats(combinedStats);
      setPreviousStats(previousResponse.data);
    } catch (error) {
      console.error("Error fetching stats:", error);
      if (error.response?.status === 401) {
        // If still getting 401 after token refresh, there might be a bigger issue
        console.error("Authentication error even after token refresh");
      }
    } finally {
      setLoading(false);
    }
  };

  // Memoize metrics calculations
  const Metrics = useMemo(
    () => [
      {
        metricName: "Total Impression",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.impressions)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.impressions || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.impressions)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.impressions || 0),
      },
      {
        metricName: "Total Followers",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.followers)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.followers || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.followers)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.followers || 0),
      },
      {
        metricName: "Following",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.following)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.following || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.following)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.following || 0),
      },
      {
        metricName: "New Followers",
        metricValue:
          stats?.stats
            ?.map((stat) => stat?.stats.newFollowers)
            .reduce((a, b) => a + b, 0) || 0,
        previousValue:
          previousStats?.stats
            ?.map((stat) => stat?.stats.newFollowers)
            .reduce((a, b) => a + b, 0) || 0,
      },
      {
        metricName: "Engagement Rate",
        metricValue: (() => {
          // Calculate total engagements across all channels
          const totalEngagements =
            stats?.stats
              ?.map((stat) => stat?.stats.engagements)
              .reduce((a, b) => a + b, 0) || 0;

          // Calculate total impressions across all channels including Instagram
          const totalImpressions =
            (stats?.stats
              ?.map((stat) => stat?.stats.impressions)
              .reduce((a, b) => a + b, 0) || 0) +
            (stats?.instagram?.[0]?.stats?.impressions || 0);

          return (totalEngagements / (totalImpressions || 1)) * 100;
        })(),
        previousValue: (() => {
          // Calculate previous total engagements across all channels
          const prevTotalEngagements =
            previousStats?.stats
              ?.map((stat) => stat?.stats.engagements)
              .reduce((a, b) => a + b, 0) || 0;

          // Calculate previous total impressions across all channels including Instagram
          const prevTotalImpressions =
            (previousStats?.stats
              ?.map((stat) => stat?.stats.impressions)
              .reduce((a, b) => a + b, 0) || 0) +
            (previousStats?.instagram?.[0]?.stats?.impressions || 0);

          return (prevTotalEngagements / (prevTotalImpressions || 1)) * 100;
        })(),
      },
      {
        metricName: "Total Engagement",
        metricValue:
          stats?.stats
            ?.map((stat) => stat?.stats.engagements)
            .reduce((a, b) => a + b, 0) || 0,
        previousValue:
          previousStats?.stats
            ?.map((stat) => stat?.stats.engagements)
            .reduce((a, b) => a + b, 0) || 0,
      },
      {
        metricName: "Total Reach",
        metricValue:
          (stats?.stats
            ?.map((stat) => stat?.stats.totalReach)
            .reduce((a, b) => a + b, 0) || 0) +
          (stats?.instagram?.[0]?.stats?.reach || 0),
        previousValue:
          (previousStats?.stats
            ?.map((stat) => stat?.stats.totalReach)
            .reduce((a, b) => a + b, 0) || 0) +
          (previousStats?.instagram?.[0]?.stats?.reach || 0),
      },
    ],
    [stats, previousStats]
  );

  // Memoize percentage change calculations
  const getPercentageChange = useMemo(
    () => (current, previous) => {
      if (!previous) return 0;
      const change = ((current - previous) / previous) * 100;
      return Number(change.toFixed(2));
    },
    []
  );

  const handleRefresh = async () => {
    await fetchStats();
  };

  // Show cache status
  const cacheAge = useMemo(() => {
    if (!lastFetchTime) return null;
    const minutes = Math.floor((Date.now() - lastFetchTime) / (1000 * 60));
    return `${minutes}m ago`;
  }, [lastFetchTime]);

  const handleConnect = async (platform) => {
    setSelectedPlatform(platform);
    const platformLower = platform.toLowerCase();

    if (platformLower === "shopify") {
      if (connectedChannels.includes("shopify")) {
        setShowReconnectConfirm(true);
        return;
      }
      setShowShopifyInput(true);
      return;
    }

    setConnectingPlatform(platformLower);
    setError("");

    try {
      if (platformLower === "facebook") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/facebook/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "gmail") {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/gmail/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.authUrl) {
          window.location.href = response.data.authUrl;
        }
      } else if (platformLower === "instagram") {
        // Add Instagram handling here (previously in handleInstagramConnect)
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/instagram/connect`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          const updatedChannels = new Set([
            ...(userDocument.connectedChannels || []),
            "instagram",
          ]);

          dispatch(
            setUserDocument({
              ...userDocument,
              instagramBusinessAccountId: response.data.instagramAccount.id,
              instagramUsername: response.data.instagramAccount.username,
              connectedChannels: Array.from(updatedChannels),
            })
          );
        }
      }
    } catch (error) {
      setError(
        error.response?.data?.error || `Failed to connect to ${platform}`
      );
      console.error(`${platform} connection error:`, error);
    } finally {
      setConnectingPlatform(null);
      setSelectedPlatform(null);
    }
  };

  const Channels = [
    {
      img_url: facebookLogo,
      name: "Facebook",
      description: "Connect your Facebook page to start sending messages.",
      isOutlined: true,
    },
  ];

  return (
    <MainContainer>
      <div id="header">
        <H1>Stats</H1>
        {isConnected && (
          <div className="cache-info">
            {cacheAge && <H4>Last updated: {cacheAge}</H4>}
            <NavButton onClick={handleRefresh} disabled={loading}>
              {loading ? "Refreshing..." : "Refresh"}
            </NavButton>
          </div>
        )}
        <div id="buttons">
          <NavButton
            onClick={() => navigate("/social-manager/stats")}
            $isFilled={location.pathname === "/social-manager/stats"}
          >
            Stats
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/posts")}
            $isFilled={location.pathname === "/social-manager/posts"}
          >
            Posts
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/generate")}
            $isFilled={location.pathname === "/social-manager/generate"}
          >
            Generate
          </NavButton>
        </div>
      </div>
      <div id="connect-channels">
        {!isConnected &&
          Channels.map((channel) => (
            <ConnectChannelBlock
              key={channel.name}
              {...channel}
              isConnected={connectedChannels.includes(
                channel.name.toLowerCase()
              )}
              handleConnect={handleConnect}
              isLoading={connectingPlatform === channel.name.toLowerCase()}
            />
          ))}
      </div>

      {loading ? (
        <StatsSkeleton />
      ) : (
        <>
          <AnimatePresence>
            <StatsContainer
              as={motion.div}
              variants={listVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {Metrics.map((metric) => (
                <StatBlock
                  as={motion.div}
                  variants={itemVariants}
                  $isBlurred={!isConnected}
                  key={metric.metricName}
                  statName={metric.metricName}
                  statValue={metric.metricValue}
                  percent={metric.metricName === "Engagement Rate"}
                  percentageChange={getPercentageChange(
                    metric.metricValue,
                    metric.previousValue
                  )}
                  trend={getTrend(metric.metricValue, metric.previousValue)}
                />
              ))}
            </StatsContainer>
          </AnimatePresence>

          <AnimatePresence>
            <ChannelsContainer
              as={motion.div}
              variants={listVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {stats?.instagram?.map((channel) => (
                <ChannelBlock
                  as={motion.div}
                  variants={itemVariants}
                  stats={channel.stats}
                  name={channel.pageName}
                  key={`instagram-${channel.pageId}`}
                  channelType="Instagram"
                  channelId={channel.pageId}
                />
              ))}
              {stats?.stats?.map((channel) => (
                <ChannelBlock
                  as={motion.div}
                  variants={itemVariants}
                  stats={channel.stats}
                  name={channel.pageName}
                  key={`facebook-${channel.pageId}`}
                  channelType="Facebook"
                  channelId={channel.pageId}
                />
              ))}
            </ChannelsContainer>
          </AnimatePresence>
        </>
      )}
    </MainContainer>
  );
};

export default Stats;
