import styled from "../../config/styled";
import { Link } from "react-router-dom";
import theme from "./global/theme";

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthFormContainer = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AuthImageContainer = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${theme.color.gray[200]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuthForm = styled.form`
  background: ${theme.color.white};
  width: 100%;
  max-width: 450px;
  box-sizing: border-box;
  h2 {
    color: ${theme.color.dark};
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    margin: 0;
  }

  h3 {
    color: ${theme.color.gray[600]};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 26px;
  }

  p {
    color: ${theme.color.dark};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 6px;
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 26px;
  }

  .forgot-password {
    margin-top: 16px;
    transition: 0.2s;
    color: #535353;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: none;
    border: none;
    cursor: pointer;
    text-align: right;
    width: 100%;

    &:hover {
      filter: brightness(1.2);
    }
  }
`;

export const AuthInput = styled.input`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  transition: 0.2s;

  box-sizing: border-box;

  border-radius: 50px;
  border: 1.154px solid rgba(18, 18, 18, 0.2);

  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
    box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
  }

  &::placeholder {
    color: ${theme.color.gray[400]};
  }
`;

export const AuthButton = styled.button`
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: ${theme.color.primary};
  border: none;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;

  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${theme.color.info};
  }

  &:disabled {
    background: ${theme.color.gray[400]};
    cursor: not-allowed;
  }
`;

export const AuthLink = styled(Link)`
  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  text-decoration: none;
  display: flex;

  margin-top: 16px;

  span {
    color: #535353;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    transition: 0.2s;
  }

  &:hover {
    span {
      color: ${theme.color.gray[600]};
      filter: brightness(1.2);
    }
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme.color.danger};
  margin-bottom: ${theme.spacing.md};
  text-align: left;
  font-size: ${theme.font.size.sm};
  padding: ${theme.spacing.xs};
  background-color: rgba(239, 68, 68, 0.1);
  border-radius: ${theme.border.radiusSmall};
`;

export const Label = styled.span`
  color: ${theme.color.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const PhoneInputHint = styled.span`
  color: ${theme.color.gray[500]};
  font-size: 12px;
  margin-top: 4px;
  display: block;
  font-family: Poppins;
`;

export const PhoneInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

export const AreaCodeSelect = styled.select`
  height: 48px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1.154px solid rgba(18, 18, 18, 0.2);
  background: white;
  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
    box-shadow: 0 0 0 3px rgba(0, 102, 255, 0.1);
  }
`;
