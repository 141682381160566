import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ChatBotContainer,
  ChatBotHeader,
  MessagesContainer,
} from "../../pages/styles/Training.styled";
import {
  H1,
  MainContainer,
  NavButton,
} from "../../pages/styles/global/main.style";
import {
  ChatInput,
  Input,
  Message,
  MessageBubble,
  MessageTime,
  SendButton,
} from "../../pages/styles/Inbox.styled";

import { H2 } from "../../pages/styles/global/main.style";
import sendIcon from "../../assets/images/send.svg";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import {
  GenerateContainer,
  GeneratePostContainer,
  GenerateSection,
  SocialOptionsContainer,
  SocialOption,
  StoryToggle,
  MetaBoostSection,
  InputGroup,
  ActionButtons,
  ActionButton,
} from "../styles/GeneratePost.styled";
import { Toggle, ToggleInput, ToggleSlider } from "../styles/Settings.styled";
import { useLocation, useNavigate } from "react-router-dom";

const GeneratePost = () => {
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const { userDocument } = useSelector((state) => state.user);
  const userId = userDocument?.uid;
  const chatContainerRef = useRef(null);
  const [chatbotSettings, setChatbotSettings] = useState({
    logoUrl: "",
    agentName: "Social Post Generator",
  });
  const [socialOptions, setSocialOptions] = useState({
    facebook: false,
    instagram: false,
    addToStory: false,
    metaBoost: false,
    spend: 20,
    days: 7,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchChatbotSettings = async () => {
      if (!userId) return;

      const chatbotRef = doc(db, "chatbots", userId);
      const chatbotDoc = await getDoc(chatbotRef);

      if (chatbotDoc.exists()) {
        const data = chatbotDoc.data();
        setChatbotSettings({
          logoUrl: data.logoUrl || "",
          agentName: data.agentName || "Social Post Generator",
        });
      }
    };

    fetchChatbotSettings();
  }, [userId]);

  const formatMessageTime = (timestamp) => {
    if (!timestamp) return "";

    if (timestamp.toDate && typeof timestamp.toDate === "function") {
      return timestamp.toDate().toLocaleTimeString();
    }

    if (timestamp instanceof Date) {
      return timestamp.toLocaleTimeString();
    }

    return "";
  };

  const handleSendMessage = async () => {
    if (!messageInput.trim()) return;

    // Add user's message to chat
    const userMessage = {
      id: Date.now(),
      content: messageInput,
      senderId: userDocument.uid,
      timestamp: new Date(),
    };
    setMessages((prev) => [...prev, userMessage]);
    setMessageInput("");

    // TODO: Add API call to generate post
    // For now, just add a placeholder response
    const botMessage = {
      id: Date.now() + 1,
      content:
        "I'll help you generate a social media post. What platform would you like to post to?",
      senderId: "bot",
      timestamp: new Date(),
    };
    setMessages((prev) => [...prev, botMessage]);
  };

  const handleSocialOptionChange = (field, value) => {
    setSocialOptions((prev) => ({
      ...prev,
      [field]: typeof value === "number" ? value : !prev[field],
    }));
  };

  const handlePostNow = () => {
    console.log("Post Now clicked", {
      socialOptions,
      messageInput,
    });
  };

  const handleSchedule = () => {
    console.log("Schedule clicked", {
      socialOptions,
      messageInput,
    });
  };

  const handleAddMore = () => {
    console.log("Add More clicked", {
      socialOptions,
      messageInput,
    });
  };

  return (
    <MainContainer>
      <div id="header">
        <H1>Generate Post</H1>

        <div id="buttons">
          <NavButton
            onClick={() => navigate("/social-manager/stats")}
            $isFilled={location.pathname === "/social-manager/stats"}
          >
            Stats
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/posts")}
            $isFilled={location.pathname === "/social-manager/posts"}
          >
            Posts
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/generate")}
            $isFilled={location.pathname === "/social-manager/generate"}
          >
            Generate
          </NavButton>
        </div>
      </div>
      <GenerateContainer>
        <GenerateSection>
          <H2>Description</H2>
          <textarea
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            placeholder="edit description here after generating"
            disabled
          ></textarea>

          <StoryToggle>
            <span>Add to Story</span>
            <Toggle>
              <ToggleInput
                type="checkbox"
                checked={socialOptions.addToStory}
                onChange={() => handleSocialOptionChange("addToStory")}
              />
              <ToggleSlider checked={socialOptions.addToStory} />
            </Toggle>
          </StoryToggle>

          <div className="social-options">
            <SocialOption>
              <input
                type="checkbox"
                id="facebook"
                checked={socialOptions.facebook}
                onChange={() => handleSocialOptionChange("facebook")}
              />
              <label htmlFor="facebook">Facebook</label>
            </SocialOption>

            <SocialOption>
              <input
                type="checkbox"
                id="instagram"
                checked={socialOptions.instagram}
                onChange={() => handleSocialOptionChange("instagram")}
              />
              <label htmlFor="instagram">Instagram</label>
            </SocialOption>
          </div>

          <StoryToggle>
            <span>Meta Boost</span>
            <Toggle>
              <ToggleInput
                type="checkbox"
                checked={socialOptions.metaBoost}
                onChange={() => handleSocialOptionChange("metaBoost")}
              />
              <ToggleSlider checked={socialOptions.metaBoost} />
            </Toggle>
          </StoryToggle>

          {socialOptions.metaBoost && (
            <>
              <InputGroup>
                <label>Spend</label>
                <input
                  type="number"
                  value={socialOptions.spend}
                  onChange={(e) =>
                    handleSocialOptionChange("spend", Number(e.target.value))
                  }
                  min="1"
                  prefix="$"
                />
              </InputGroup>

              <InputGroup>
                <label>Day</label>
                <input
                  type="number"
                  value={socialOptions.days}
                  onChange={(e) =>
                    handleSocialOptionChange("days", Number(e.target.value))
                  }
                  min="1"
                />
              </InputGroup>
            </>
          )}

          <ActionButtons>
            <NavButton $isFilled onClick={handlePostNow}>
              Post Now
            </NavButton>
            <NavButton $isOutlined onClick={handleSchedule}>
              Schedule
            </NavButton>
            <NavButton $isOutlined onClick={handleAddMore}>
              Add More
            </NavButton>
          </ActionButtons>

          <NavButton $isFilled>Upload Image</NavButton>
        </GenerateSection>

        <ChatBotContainer ref={chatContainerRef}>
          <MessagesContainer
            style={{ minHeight: "80vh" }}
            className="messages-container"
          >
            {messages.map((message) => (
              <Message
                key={message.id}
                isUser={message.senderId === userDocument.uid}
              >
                <MessageBubble isUser={message.senderId === userDocument.uid}>
                  {message.content}
                </MessageBubble>
                <MessageTime>
                  {formatMessageTime(message.timestamp)}
                </MessageTime>
              </Message>
            ))}
          </MessagesContainer>
          <ChatInput>
            <Input
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              placeholder="Enter your message here..."
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <SendButton onClick={handleSendMessage}>
              Send <img src={sendIcon} alt="Send Icon" />
            </SendButton>
          </ChatInput>
        </ChatBotContainer>
      </GenerateContainer>
    </MainContainer>
  );
};

export default GeneratePost;

//Upload image to firebase storage
//call n8n api to generate post
//get description
//if schedule call n8n flow
//if publish now, publish post via api
//update firestore with post data
