import {
  SignupBenefitsContainer,
  SignupBenefitsMiddleContainer,
  SignupBenefitsList,
  SignupBenefitsItem,
  SignupBenefitsButton,
} from "./styles/SignupBenefits.styled";
import {
  H1,
  H2,
  H3,
  H4,
  MainContainer,
  NavButton,
} from "./styles/global/main.style";
import signupBenefitsImage from "../assets/images/benefits-cycle.png";
import ecommerceMascot from "../assets/images/EcommerceBot-Mascot.png";
import { useNavigate } from "react-router-dom";

const SignupBenefits = () => {
  const benefits = [
    "Join a vibrant Facebook Community 🤝",
    "Weekly calls with our team 💭",
    "Vote Features & get 7 days free for bug finding 🎯",
    "Be part of early access programs 🚀",
  ];
  const navigate = useNavigate();
  return (
    <SignupBenefitsContainer>
      <img
        className="ecommerce-mascot"
        id="ecommerce-mascot1"
        src={ecommerceMascot}
        alt="Ecommerce Mascot"
      />
      <img
        className="ecommerce-mascot"
        id="ecommerce-mascot2"
        src={ecommerceMascot}
        alt="Ecommerce Mascot"
      />
      <img
        className="ecommerce-mascot"
        id="ecommerce-mascot3"
        src={ecommerceMascot}
        alt="Ecommerce Mascot"
      />
      <div className="signup-benefits-title">
        <H1>
          Help Us Build the{" "}
          <span id="future-of-ecommerce">Future of Ecommerce</span>!
        </H1>
        <H2>Collaborate. Innovate. Automate.</H2>
      </div>
      <SignupBenefitsMiddleContainer>
        <div className="signup-benefits-left">
          <H2>Benefits</H2>
          <SignupBenefitsList>
            {benefits.map((benefit, index) => (
              <SignupBenefitsItem key={index}>{benefit}</SignupBenefitsItem>
            ))}
          </SignupBenefitsList>
        </div>
        <img src={signupBenefitsImage} alt="Signup Benefits" />
      </SignupBenefitsMiddleContainer>
      <div className="signup-benefits-btn">
        <SignupBenefitsButton onClick={() => navigate("/shopify")}>
          Connect Shopify
        </SignupBenefitsButton>
        <H4>
          Join community when <span>Shopify</span> is Connected
        </H4>{" "}
      </div>
    </SignupBenefitsContainer>
  );
};

export default SignupBenefits;
