import { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { PostsContainer, PostBlock } from "../styles/Posts.styled";
import { useSelector } from "react-redux";
import { PostImage } from "../styles/Posts.styled";
import {
  H1,
  H2,
  H3,
  MainContainer,
  NavButton,
  Select,
} from "../styles/global/main.style";
import { useLocation, useNavigate } from "react-router-dom";
import facebookLogo from "../../assets/images/facebook.svg";
import instagramLogo from "../../assets/images/instagram.svg";
import likesIcon from "../../assets/images/likes.svg";
import commentsIcon from "../../assets/images/comments.svg";
import sharesIcon from "../../assets/images/shares.svg";

const Posts = () => {
  const [postsToShow, setPostsToShow] = useState(8);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageId, setPageId] = useState(null);

  const userDocument = useSelector((state) => state.user.userDocument);
  const location = useLocation();
  const navigate = useNavigate();

  // Memoize currentPosts calculation
  const currentPosts = useMemo(
    () => posts.filter((post) => post.mediaUrl).slice(0, postsToShow),
    [posts, postsToShow]
  );

  const platform = posts[0]?.platform;

  // Memoize fetchPosts function
  const fetchPosts = useCallback(
    async (id) => {
      if (!id || loading) return;

      try {
        setLoading(true);
        const isInstagram =
          userDocument?.instagramPage?.instagram_business_account?.id === id;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/social-manager/post-data/${userDocument.uid}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              pageId: id,
              platform: isInstagram ? "instagram" : "facebook",
            },
          }
        );

        // Calculate engagement rate for each post
        const postsWithEngagement = response.data.posts.map((post) => ({
          ...post,
          engagementRate:
            post.impressions > 0
              ? (
                  ((post.likes + post.comments + post.shares) /
                    post.impressions) *
                  100
                ).toFixed(2)
              : 0,
        }));

        setPosts(postsWithEngagement);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, userDocument]
  );

  // Memoize handler functions
  const handleSeeMore = useCallback(() => {
    setPostsToShow((prevCount) => prevCount + 8);
  }, []);

  const handleSeeLess = useCallback(() => {
    setPostsToShow(8);
  }, []);

  const handleChannelChange = useCallback(
    (e) => {
      const newPageId = e.target.value;
      navigate(`?channelId=${newPageId}`);
      setPageId(newPageId);
      setPostsToShow(8);
      fetchPosts(newPageId);
    },
    [fetchPosts, navigate]
  );

  // Add useEffect to handle URL params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const channelId = params.get("channelId");

    if (channelId) {
      setPageId(channelId);
      fetchPosts(channelId);
    } else if (userDocument?.facebookPages?.[0]?.id) {
      const defaultPageId = userDocument.facebookPages[0].id;
      navigate(`?channelId=${defaultPageId}`, { replace: true });
      setPageId(defaultPageId);
      fetchPosts(defaultPageId);
    }
  }, [userDocument, location.search]);

  return (
    <MainContainer>
      <div id="header">
        <H1>Posts</H1>

        {/* Add channel dropdown */}
        <div className="channel-dropdown">
          {platform && (
            <img
              src={platform === "facebook" ? facebookLogo : instagramLogo}
              alt="Platform"
            />
          )}
          <Select onChange={handleChannelChange} value={pageId || ""}>
            {userDocument?.facebookPages?.map((page) => (
              <option key={page.id} value={page.id}>
                {page.name} (Facebook)
              </option>
            ))}

            {userDocument?.instagramPage?.instagram_business_account?.id && (
              <option
                key={userDocument.instagramPage.instagram_business_account.id}
                value={userDocument.instagramPage.instagram_business_account.id}
              >
                {userDocument.instagramPage.name} (Instagram)
              </option>
            )}
          </Select>
        </div>

        <div id="buttons">
          <NavButton
            onClick={() => navigate("/social-manager/stats")}
            $isFilled={location.pathname === "/social-manager/stats"}
          >
            Stats
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/posts")}
            $isFilled={location.pathname === "/social-manager/posts"}
          >
            Posts
          </NavButton>
          <NavButton
            onClick={() => navigate("/social-manager/generate")}
            $isFilled={location.pathname === "/social-manager/generate"}
          >
            Generate
          </NavButton>
        </div>
      </div>
      <PostsContainer>
        {currentPosts.map((post) => (
          <PostBlock key={post.id}>
            {post.mediaUrl && (
              <>
                <div className="engagement-metrics">
                  <PostImage src={post.mediaUrl} alt="Post media" />
                  <H3>Engagement Rate:</H3> <span>{post.engagementRate}%</span>
                </div>
                <div className="post-metrics">
                  <div>
                    <img src={likesIcon} alt="Likes" />
                    {post.likes.toLocaleString()}
                  </div>
                  <div>
                    <img src={commentsIcon} alt="Comments" />
                    {post.comments.toLocaleString()}
                  </div>
                  <div>
                    <img src={sharesIcon} alt="Shares" />
                    {post.shares.toLocaleString()}
                  </div>
                </div>
              </>
            )}{" "}
            {/* {post.message && <p>{post.message}</p>} */}
            {/* <small>
              {new Date(post.createdAt.seconds * 1000).toLocaleDateString()}
            </small> */}
            {/* <a
              href={post.permalinkUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Facebook
            </a> */}
          </PostBlock>
        ))}

        {/* Replace pagination controls with See More/Less buttons */}
        {!loading && (
          <>
            {posts.length > postsToShow && (
              <NavButton $isOutlined onClick={handleSeeMore}>
                See More
              </NavButton>
            )}
            {postsToShow > 8 && (
              <NavButton $isOutlined onClick={handleSeeLess}>
                See Less
              </NavButton>
            )}
          </>
        )}

        {loading && <div>Loading posts...</div>}
        {!loading && posts.length === 0 && <div>No posts found</div>}
      </PostsContainer>
    </MainContainer>
  );
};

export default Posts;
