import styled from "styled-components";
import theme from "../styles/global/theme";
export const GenerateContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  height: 80vh;
`;

export const GenerateSection = styled.div`
  display: flex;
  padding: 28px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  border-radius: 12px;
  background: #f3f4f6;
  width: 45%;
  box-sizing: border-box;
  textarea {
    &:disabled {
      position: relative;
      cursor: not-allowed;
    }
    box-sizing: border-box;
    width: 100%;
    min-height: 200px;
    padding: 24px 16px;
    border-radius: 16px; /* Removed duplicate border-radius declaration */
    border: 1px solid #e5e7eb;
    background: #e8eaef; /* Removed duplicate background declaration */
    font-size: 14px; /* Removed duplicate font-size declaration */
    line-height: 1.5;
    resize: vertical;
    color: var(--Black, #0a0b1f);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    border: none;

    min-height: 211px;

    &:focus {
      outline: none;
      border-color: #6b7280;
      border: none;
    }
  }

  .social-options {
    display: flex;

    gap: 24px;
  }
`;
export const SocialOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    /* Hide default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Custom checkbox styling */
    width: 20px;
    height: 20px;
    border: 2px solid ${theme.color.gray[300]};
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    background: white;

    /* Checked state */
    &:checked {
      background: ${theme.color.primary};
      border-color: ${theme.color.primary};

      /* Checkmark */
      &:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 1px;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    /* Hover state */
    &:hover {
      border-color: ${theme.color.primary};
    }
  }

  label {
    font-size: 14px;
    color: ${theme.color.gray[900]};
    cursor: pointer;
  }
`;

export const StoryToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${theme.color.gray[200]};
  gap: 12px;
`;

export const MetaBoostSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  label {
    font-size: 14px;
    color: ${theme.color.gray[600]};
  }

  input {
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    background: #e8eaef;
    display: flex;
    height: 44px;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    border: none;

    color: ${theme.color.gray[900]};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:focus {
      outline: none;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

export const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) =>
    props.$primary
      ? `
    background: ${theme.color.primary};
    color: white;
  `
      : `
    background: white;
    color: ${theme.color.primary};
    border: 1px solid ${theme.color.primary};
  `}

  &:hover {
    opacity: 0.9;
  }
`;
