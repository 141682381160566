import styled from "styled-components";
import { NavButton } from "../styles/global/main.style";
// Base button styles for common button properties
const BaseButton = styled(NavButton)`
  position: fixed;
  bottom: 20px;
  padding: 10px 20px;
  color: white;
  border: none;
  width: 100px;
  cursor: pointer;
  z-index: 1000;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

// Export button positioned at bottom right of screen
export const ExportButton = styled(BaseButton)`
  right: 20px;
  background-color: #007bff;

  &:hover {
    background-color: #0056b3;
  }
`;

// Save button positioned to the left of the export button
export const SaveButton = styled(BaseButton)`
  right: 140px;
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;
