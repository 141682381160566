import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB1Fxy9cqvdovel4s1VDfzF81r4c22QB5Y",
  authDomain: "ecommercebot-a076f.firebaseapp.com",
  projectId: "ecommercebot-a076f",
  storageBucket: "ecommercebot-a076f.firebasestorage.app",
  messagingSenderId: "165070327372",
  appId: "1:165070327372:web:b4b94bf6494cfe56662ee9",
  measurementId: "G-YQFPGGFC8S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Auth
const auth = getAuth(app);
export const db = getFirestore(app);

// Set persistence to LOCAL (7 days)
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Firebase persistence error:", error);
});

export { auth };
export default app;
