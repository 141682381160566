import styled from "styled-components";
import theme from "./global/theme";
import { motion } from "framer-motion";

export const TrainingLayout = styled(motion.div)`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding-right: 24px;
  .auto-trainer-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 80%;
    height: 80vh;
  }

  .auto-trainer-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .trainer-toggle {
    display: flex;
    align-items: center;
  }

  #training-percentage {
    display: flex;
    width: 160px;
    padding: 12px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 46px;
    background: ${theme.color.primary};
    color: ${theme.color.white};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const FAQandFilesContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 28px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  width: 50%;
  background: ${theme.color.gray[100]};
  height: 80vh;

  .add-buttons {
    display: flex;
    gap: 12px;
  }
`;

export const ChatBotContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: ${theme.color.gray[100]};
  flex-grow: 1;
  overflow: hidden;
`;

export const ChatBotHeader = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 12px;

  #header-logo {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const FAQContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;

  position: relative;
  width: 100%;
  box-sizing: border-box;

  #cancel-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const FAQItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${theme.color.gray[300]};
  padding: 12px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
`;

export const FilesContainer = styled.div`
  .files-list {
    margin: 1rem 0;
  }

  .file-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #eee;

    .file-url {
      color: #666;
      font-size: 0.8em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const FAQInput = styled.input`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-content: center;
  gap: 109px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 12px;
  background: ${theme.color.gray[300]};
  border: none;
`;

export const TrainerToggleWrapper = styled.div`
  .toggle-container {
    margin: 0;
  }
`;

export const MessagesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  padding: 20px;
  height: 100%;

  flex-grow: 1;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome/Safari/Opera */
  }

  > :first-child {
    margin-top: auto;
  }
`;

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  background: var(--light-gray);
  border-radius: 8px;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  h3 {
    margin: 0 0 8px 0;
    color: var(--dark-gray);
  }

  h4 {
    margin: 0;
    font-size: 24px;
    color: var(--primary);
  }
`;
