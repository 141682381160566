import {
  MainContainer,
  NavButton,
  H1,
  H3,
  SearchInput,
} from "./styles/global/main.style";
import { motion, AnimatePresence } from "framer-motion";
import {
  dropdownContentVariants,
  dropdownIconVariants,
  contactExpandVariants,
  listVariants,
  itemVariants,
} from "./styles/global/framer-motion-variants";
import { useState, useEffect, memo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PostsContainer } from "./styles/Posts.styled";
import {
  ContactBlock,
  DropdownIcon,
  SkeletonBlock,
  SkeletonContent,
  SkeletonIcon,
  SkeletonText,
} from "./styles/Contacts.styled";
import dropDown from "../assets/images/dropDown.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import facebookIcon from "../assets/images/facebook.svg";
import instagramIcon from "../assets/images/instagram.svg";
import shopifyIcon from "../assets/images/shopifyLogo.png";

const ContactSkeleton = () => (
  <SkeletonBlock variants={itemVariants}>
    <SkeletonContent>
      <SkeletonIcon />
      <SkeletonText $width="200px" />
      <SkeletonText $width="100px" style={{ marginLeft: "auto" }} />
    </SkeletonContent>
  </SkeletonBlock>
);

const Contacts = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedIds, setExpandedIds] = useState({});
  const { userDocument } = useSelector((state) => state.user);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contactsToShow, setContactsToShow] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const userId = userDocument?.uid;

  const getContacts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/contacts?userId=${userId}`
      );
      setContacts(response.data);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, [userId]);

  const toggleExpand = (contactId) => {
    setExpandedIds((prev) => ({
      ...prev,
      [contactId]: !prev[contactId],
    }));
  };

  const handleSeeMore = () => {
    setContactsToShow((prevCount) => prevCount + 8);
  };

  const handleSeeLess = () => {
    setContactsToShow(8);
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentContacts = filteredContacts.slice(0, contactsToShow);

  return (
    <MainContainer>
      <div id="header">
        <H1>Contacts</H1>
        <SearchInput
          type="text"
          placeholder="Search contacts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <PostsContainer
        as={motion.div}
        variants={listVariants}
        initial="hidden"
        animate="visible"
        $isContacts
      >
        {loading ? (
          Array.from({ length: 8 }).map((_, index) => (
            <ContactSkeleton key={index} />
          ))
        ) : (
          <AnimatePresence>
            {currentContacts.map((contact, index) => (
              <ContactBlock
                key={contact.id || index}
                variants={contactExpandVariants}
                initial="hidden"
                animate={expandedIds[contact.id] ? "visible" : "hidden"}
                exit="hidden"
                layout
              >
                <div className="name">
                  <div className="contact-info">
                    <div className="name-platform-icon">
                      {contact.source === "facebook" && (
                        <img src={facebookIcon} alt="facebook" />
                      )}
                      {contact.source === "instagram" && (
                        <img src={instagramIcon} alt="instagram" />
                      )}
                      {contact.source === "shopify" && (
                        <img src={shopifyIcon} alt="shopify" />
                      )}
                      <H3>{contact.full_name}</H3>
                    </div>

                    {expandedIds[contact.id] && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={dropdownContentVariants}
                      >
                        <p>Country: {contact.country || "N/A"}</p>
                        <p>Postal: {contact.postal || "N/A"}</p>
                        <p>Address: {contact.address || "N/A"}</p>
                        <p>Phone: {contact.phone || "N/A"}</p>
                        <p>Email: {contact.email || "N/A"}</p>
                      </motion.div>
                    )}
                  </div>

                  <div className="order-info">
                    {expandedIds[contact.id] && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={dropdownContentVariants}
                      >
                        <H3>Product Purchase</H3>
                        {contact.order_history?.length > 0 &&
                          contact.order_history.map((order, orderIndex) => (
                            <p key={orderIndex}>
                              Order:{" "}
                              {order.line_items
                                .map((item) => item.title)
                                .join(", ")}{" "}
                              - $
                              {order.line_items
                                .map((item) => item.price)
                                .join(", ")}
                            </p>
                          ))}
                        <p>Status: {contact.status}</p>
                      </motion.div>
                    )}
                  </div>
                  <DropdownIcon
                    src={dropDown}
                    alt="drop down"
                    onClick={() => toggleExpand(contact.id)}
                    animate={expandedIds[contact.id] ? "open" : "closed"}
                    variants={dropdownIconVariants}
                  />
                </div>
              </ContactBlock>
            ))}
          </AnimatePresence>
        )}

        {!loading && filteredContacts.length > contactsToShow && (
          <NavButton $isOutlined onClick={handleSeeMore}>
            See More
          </NavButton>
        )}
        {!loading && contactsToShow > 8 && (
          <NavButton $isOutlined onClick={handleSeeLess}>
            See Less
          </NavButton>
        )}
      </PostsContainer>
    </MainContainer>
  );
});

export default Contacts;
