import {
  ChannelBlockContainer,
  ChannelBlockHeader,
  ChannelBlockIcon,
  ChannelBlockName,
  ChannelBlockStat,
  ChannelBlockStatName,
  ChannelBlockStatValue,
  ChannelBlockStats,
  ChannelBlockButtons,
} from "./styles/ChannelBlock.styled";

import Instagram from "../assets/images/ig-logo.png";
import Facebook from "../assets/images/fb-logo.png";
import moreOptions from "../assets/images/more-options-dots.svg";
import { NavButton } from "../pages/styles/global/main.style";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ChannelBlock = (props) => {
  const { stats, name, channelType, channelId } = props;

  const navigate = useNavigate();

  const [showDeeperInsights, setShowDeeperInsights] = useState(false);

  return (
    <ChannelBlockContainer>
      <ChannelBlockHeader>
        <div className="icon-name">
          <ChannelBlockIcon
            src={channelType === "Instagram" ? Instagram : Facebook}
          />
          <div className="channel-reconnect-button">
            <ChannelBlockName>{name}</ChannelBlockName>
            <button
              id="reconnect-button"
              onClick={() => {
                console.log("Reconnect");
              }}
            >
              Reconnect
            </button>
          </div>
        </div>
        <button
          className="more-options-button"
          onClick={() => {
            console.log("More options");
          }}
        >
          <img src={moreOptions} alt="More options" />
        </button>
      </ChannelBlockHeader>
      <ChannelBlockStats>
        {[
          { name: "Followers", value: stats?.followers },
          ...(channelType === "Instagram"
            ? [{ name: "Following", value: stats?.following }]
            : []),
          {
            name: "Total Posts",
            value:
              channelType === "Instagram" ? stats?.posts : stats?.totalPosts,
          },
          { name: "Reach", value: stats?.totalReach },
          { name: "Impressions", value: stats?.impressions },
          ...(channelType === "Facebook"
            ? [{ name: "Total Likes", value: stats?.totalLikes }]
            : []),
          ...(showDeeperInsights
            ? [
                ...(channelType === "Instagram"
                  ? [{ name: "Total Likes", value: stats?.totalLikes }]
                  : []),
                { name: "Total Comments", value: stats?.totalComments },
                { name: "Total Shares", value: stats?.totalShares },
                {
                  name: "Engagement Rate",
                  value: stats?.followers
                    ? (
                        ((stats?.totalLikes +
                          stats?.totalComments +
                          stats?.totalShares) /
                          (stats?.totalPosts || 1) /
                          stats?.followers) *
                        100
                      ).toFixed(2) + "%"
                    : "0%",
                },
              ]
            : []),
        ].map((stat, index) => (
          <ChannelBlockStat key={index}>
            <ChannelBlockStatName>{stat.name}</ChannelBlockStatName>
            <ChannelBlockStatValue>{stat.value || 0}</ChannelBlockStatValue>
          </ChannelBlockStat>
        ))}
      </ChannelBlockStats>
      <ChannelBlockButtons>
        <NavButton
          $isStats={true}
          onClick={() => {
            navigate(`/social-manager/posts?channelId=${channelId}`);
          }}
        >
          See all Posts
        </NavButton>
        <NavButton
          $isOutlined={true}
          onClick={() => {
            setShowDeeperInsights(!showDeeperInsights);
          }}
        >
          {showDeeperInsights ? "Show Less" : "Deeper Insights"}
        </NavButton>
      </ChannelBlockButtons>
    </ChannelBlockContainer>
  );
};

export default ChannelBlock;
