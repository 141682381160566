import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import navigationReducer from "./slices/navigationSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    navigation: navigationReducer,
  },
});
