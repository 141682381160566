import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import theme from "../../pages/styles/global/theme";

export const ConnectChannelsContainer = styled(motion.div)`
  display: flex;
  width: 33%;
  padding: 28px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${theme.color.gray[100]};

  ${(props) =>
    props.$isOutlined &&
    css`
      border: 1.5px solid ${theme.color.primary};
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    `}
`;

export const ConnectChannelImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const ConnectChannelName = styled.h2`
  color: ${theme.color.black};
  text-align: center;
  font-family: ${theme.font.fontFamilyInter};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 12px 0;
`;

export const ConnectChannelDescription = styled.h3`
  color: ${theme.color.gray[500]};
  text-align: center;
  font-family: ${theme.font.fontFamilyInter};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  margin: 0;
  margin-bottom: 12px;
`;
