import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import {
  ChatBotContainer,
  ChatButton,
  ChatWindow,
  ChatHeader,
  ChatMessages,
  Message,
  InputContainer,
  Input,
  SendButton,
} from "./styles/ChatBot.styled";

const ChatBot = ({ agentName, brandColor, avatar, enabled, logoUrl }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const { userDocument } = useSelector((state) => state.user);
  const messagesEndRef = React.useRef(null);

  React.useEffect(() => {
    const initialMessage = {
      content: "Hello this is what a message will look like",
      isBot: true,
      timestamp: new Date(),
    };
    setMessages([initialMessage]);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages, isOpen]);

  const handleSend = async () => {
    if (!input.trim()) return;

    const userMessage = {
      content: input,
      isBot: false,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");

    const botMessage = {
      content: "Hello this is what a message/response will look like",
      isBot: true,
      timestamp: new Date(),
    };

    setTimeout(() => {
      setMessages((prev) => [...prev, botMessage]);
    }, 1000);

    try {
      const response = await fetch("/api/messages/widget-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userDocument.uid,
          message: input,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const botMessage = {
        content: "Message received! We'll get back to you soon.",
        isBot: true,
        timestamp: new Date(),
      };

      setTimeout(() => {
        setMessages((prev) => [...prev, botMessage]);
      }, 1000);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <ChatBotContainer>
      <ChatButton
        style={{ background: brandColor }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="chat-button-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            className="size-3"
          >
            <path
              fillRule="evenodd"
              d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </ChatButton>

      {isOpen && enabled && (
        <ChatWindow>
          <ChatHeader style={{ background: brandColor }}>
            {logoUrl && <img src={logoUrl} alt={agentName} />}
            {agentName}!
          </ChatHeader>

          <ChatMessages>
            {messages.map((message, index) => (
              <Message
                brandColor={brandColor}
                key={index}
                isBot={message.isBot}
              >
                {message.content}
              </Message>
            ))}
            <div ref={messagesEndRef} />
          </ChatMessages>

          <InputContainer>
            <Input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
            ></Input>
            <SendButton style={{ background: brandColor }} onClick={handleSend}>
              Send{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="30"
                viewBox="0 0 29 30"
                fill="none"
              >
                <path
                  d="M16.3782 9.36672L8.81211 11.8887C3.72684 13.5799 3.72684 16.3612 8.81211 18.0523L10.3501 18.565C10.7979 18.7182 11.1456 19.0659 11.2988 19.5137L11.8055 21.0458C13.4967 26.131 16.2721 26.1369 17.9691 21.0458L20.4912 13.4797C21.6225 10.0856 19.7723 8.23535 16.3782 9.36672ZM16.9733 13.7685L13.7913 16.9504C13.5497 17.192 13.149 17.192 12.9074 16.9504C12.6658 16.7088 12.6658 16.3082 12.9074 16.0666L16.0894 12.8846C16.331 12.643 16.7317 12.643 16.9733 12.8846C17.2149 13.1262 17.2149 13.5269 16.9733 13.7685Z"
                  fill="white"
                />
              </svg>
            </SendButton>
          </InputContainer>
        </ChatWindow>
      )}
    </ChatBotContainer>
  );
};

export default ChatBot;
