import React from "react";
import { motion } from "framer-motion";
import {
  MainContainer,
  H1,
  H3,
  NavButton,
  ComingSoonContainer,
  ComingSoonIllustration,
  ComingSoonDescription,
} from "./styles/global/main.style";
import {
  comingSoonContainerVariants,
  comingSoonItemVariants,
} from "./styles/global/framer-motion-variants";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <ComingSoonContainer
        variants={comingSoonContainerVariants}
        initial="hidden"
        animate="visible"
      >
        <ComingSoonIllustration variants={comingSoonItemVariants}>
          🚀
        </ComingSoonIllustration>
        <H1 as={motion.h1} variants={comingSoonItemVariants}>
          Coming Soon!
        </H1>
        <H3 as={motion.h3} variants={comingSoonItemVariants}>
          We're working on something amazing
        </H3>
        <ComingSoonDescription variants={comingSoonItemVariants}>
          Our team is developing a powerful suite of AI-powered advertising
          tools. These tools will help automate and optimize your ad campaigns
          across multiple platforms to maximize your marketing ROI.
        </ComingSoonDescription>
        <motion.div variants={comingSoonItemVariants}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <NavButton $isFilled onClick={() => navigate("/dashboard")}>
              Back to Dashboard
            </NavButton>
          </motion.div>
        </motion.div>
      </ComingSoonContainer>
    </MainContainer>
  );
};

export default ComingSoon;
