import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { MainContainer, H1, H2, NavButton } from "./styles/global/main.style";
import AddTaskModal from "../components/AddTaskModal";
import {
  TaskList,
  TaskItem,
  MetricsContainer,
  DoButton,
} from "./styles/Dashboard.styled";
import checkCircle from "../assets/images/check-circle.svg";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";

const Tasks = () => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [customTasks, setCustomTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDocument } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const getValidToken = async () => {
    try {
      // Wait for Firebase to initialize
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      const currentUser = auth.currentUser;
      if (currentUser) {
        // Force token refresh
        const token = await currentUser.getIdToken(true);
        localStorage.setItem("token", token);
        return token;
      }
      return null;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  const defaultTasks = [
    {
      complete: userDocument?.connectedChannels?.includes("instagram"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Instagram",
      taskUrl: "/channels",
    },
    {
      complete: userDocument?.connectedChannels?.includes("facebook"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Facebook",
      taskUrl: "/channels",
    },
    {
      complete: userDocument?.connectedChannels?.includes("google"),
      createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
      taskName: "Connect Google",
      taskUrl: "/channels",
    },
    // {
    //   complete: userDocument?.scheduledPosts?.length > 0,
    //   createdAt: "November 13, 2024 at 1:47:46 AM UTC-5",
    //   taskName: "Schedule & boost your first social media post",
    //   taskUrl: "/schedule",
    // },
  ];

  // Load custom tasks on mount
  useEffect(() => {
    const loadCustomTasks = async () => {
      if (!userDocument?.uid) return;

      try {
        const token = await getValidToken();
        if (!token) {
          console.error("No valid token available");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/tasks`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCustomTasks(response.data.tasks);
      } catch (error) {
        console.error("Error loading custom tasks:", error);
      }
    };

    loadCustomTasks();
  }, [userDocument?.uid]);

  // Handle adding a new task
  const handleAddTask = async (taskName) => {
    try {
      const token = await getValidToken();
      if (!token) {
        throw new Error("No valid token available");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tasks/create`,
        { title: taskName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomTasks([...customTasks, response.data.task]);
      setShowAddTaskModal(false);
    } catch (error) {
      console.error("Error creating task:", error);
      throw error;
    }
  };

  // Handle completing a task
  const handleCompleteTask = async (taskId) => {
    try {
      const token = await getValidToken();
      if (!token) {
        throw new Error("No valid token available");
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tasks/complete/${taskId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomTasks(
        customTasks.map((task) =>
          task.id === taskId ? { ...task, status: "completed" } : task
        )
      );
    } catch (error) {
      console.error("Error completing task:", error);
    }
  };

  // Combine default and custom tasks
  const allTasks = useMemo(() => {
    return [
      ...defaultTasks,
      ...customTasks.map((task) => ({
        taskName: task.title,
        complete: task.status === "completed",
        createdAt: task.createdAt,
        isCustom: true,
        id: task.id,
      })),
    ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [defaultTasks, customTasks]);

  // Calculate uncompleted tasks count
  const uncompletedTasksCount = allTasks.filter(
    (task) => !task.complete
  ).length;

  return (
    <MainContainer>
      <div id="header">
        <div className="header-text">
          <H1>Tasks</H1>
          <H2>You have {uncompletedTasksCount} tasks to complete today</H2>
        </div>
      </div>

      <MetricsContainer>
        <TaskList>
          <div className="task-list-header">
            <H2>Tasks Overview</H2>
            <div className="task-list-header-buttons">
              <NavButton $isFilled onClick={() => setShowAddTaskModal(true)}>
                Add Task
              </NavButton>
            </div>
          </div>
          {allTasks.map((task) => (
            <div
              className="task-item-container"
              key={task.isCustom ? task.id : task.taskName}
            >
              <TaskItem>{task.taskName}</TaskItem>
              {!task.complete ? (
                <DoButton
                  onClick={() =>
                    task.isCustom
                      ? handleCompleteTask(task.id)
                      : navigate(task.taskUrl)
                  }
                >
                  {task.isCustom ? "Complete" : "Do Now"}
                </DoButton>
              ) : (
                <DoButton $isDone>
                  <img src={checkCircle} alt="check" /> Done
                </DoButton>
              )}
            </div>
          ))}
        </TaskList>
      </MetricsContainer>

      <AddTaskModal
        isOpen={showAddTaskModal}
        onClose={() => setShowAddTaskModal(false)}
        onAddTask={handleAddTask}
      />
    </MainContainer>
  );
};

export default Tasks;
