import {
  StatBlockContainer,
  StatBlockText,
  SubtitleText,
  TitleText,
} from "./styles/StatBlock.styled";

import upArrow from "../assets/images/upArrow.svg";
import downArrow from "../assets/images/downArrow.svg";
const StatBlock = (props) => {
  const {
    statName,
    statValue,
    negative,
    percentageChange,
    percent,
    $isBlurred,
  } = props;

  // Calculate display value based on blur state
  const displayValue = $isBlurred ? 7327 : statValue;

  return (
    <StatBlockContainer
      style={{
        background: percentageChange < 0 ? "#FFF9F5" : "#F3F4F6",
        filter: $isBlurred ? "blur(3px)" : "none",
      }}
    >
      <StatBlockText negative={negative}>
        <SubtitleText>{statName}</SubtitleText>
        <TitleText>
          {percent ? displayValue.toFixed(2) : displayValue}
          {percent && "%"}
        </TitleText>

        <div className="trend-text-container">
          <SubtitleText>Since last week</SubtitleText>
          {percentageChange !== 0 && (
            <div className="trend-container">
              <SubtitleText
                style={{ color: percentageChange > 0 ? "#0061FE" : "#F25A53" }}
              >
                {percentageChange} %
              </SubtitleText>
              <img
                src={percentageChange > 0 ? upArrow : downArrow}
                alt="Trend arrow"
              />
            </div>
          )}
        </div>
      </StatBlockText>
    </StatBlockContainer>
  );
};

export default StatBlock;
