import styled from "styled-components";
import theme from "../../pages/styles/global/theme";

export const StatBlockContainer = styled.div`
  display: flex;
  width: 275px;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background: #f3f4f6;
  ${(props) => props.negative && "background: #FFF9F5;"}
`;

export const StatBlockText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  width: 100%;

  .trend-text-container {
    display: flex;
    gap: 24px;
    width: 100%;
  }

  .trend-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const SubtitleText = styled.span`
  color: ${theme.color.gray[600]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TitleText = styled.span`
  color: ${theme.color.black};
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
