import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { auth } from "../../config/firebase";

// Initial state for the user slice
const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  userDocument: JSON.parse(localStorage.getItem("userDocument")) || null,
  token: localStorage.getItem("token"),
  isAuthenticated: !!localStorage.getItem("token"),
  loading: true,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Set loading state
    setLoading: (state, action) => {
      state.loading = action.payload;
      state.error = null;
    },
    // Set error state
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // Set user data after successful auth
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;

      state.loading = false;
      state.error = null;
    },
    // Clear user data on logout
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.userDocument = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userDocument");
    },
    // Set token
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem("token", action.payload);
    },
    // Add setProfile reducer
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    // Add a new reducer for user document
    setUserDocument: (state, action) => {
      state.userDocument = action.payload;
      localStorage.setItem("userDocument", JSON.stringify(action.payload));
    },
  },
});

// Export actions
export const {
  setLoading,
  setError,
  setUser,
  clearUser,
  setToken,
  setProfile,
  setUserDocument,
} = userSlice.actions;

// Async thunk for registration and auto-login
export const registerUser = (userData) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // Register the user
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/register`,
      userData
    );

    // Auto login after registration
    const loginResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/login`,
      {
        email: userData.email,
        password: userData.password,
      }
    );

    // Set token in state and localStorage
    dispatch(setToken(loginResponse.data.token));

    // Fetch user data if needed
    const userResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/auth/me`,
      {
        headers: { Authorization: `Bearer ${loginResponse.data.token}` },
      }
    );

    dispatch(setUser(userResponse.data));
    return true; // Return true for successful registration
  } catch (error) {
    dispatch(setError(error.response?.data?.error || "Registration failed"));
    return false;
  }
};

// Async thunk for login
export const loginUser = (credentials) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // 1. Login
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/login`,
      credentials
    );

    // 2. Set token
    dispatch(setToken(response.data.token));

    // 3. Fetch user data with the token
    const userResponse = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/auth/me`,
      {
        headers: { Authorization: `Bearer ${response.data.token}` },
      }
    );

    // 4. Store user data in Redux
    dispatch(setUserDocument(userResponse.data));
    dispatch(
      setUser({
        email: userResponse.data.email,
        emailVerified: userResponse.data.emailVerified,
        displayName: userResponse.data.displayName,
        photoURL: userResponse.data.photoURL,
      })
    );

    return true;
  } catch (error) {
    dispatch(setError(error.response?.data?.error || "Login failed"));
    return false;
  }
};

// Async thunk for logout
export const logout = () => async (dispatch) => {
  try {
    // Sign out from Firebase
    await auth.signOut();

    // Clear all relevant localStorage items
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("userDocument");
    localStorage.removeItem("storeMetrics");
    localStorage.removeItem("socialStats");

    // Clear Redux state
    dispatch(clearUser());
    return true;
  } catch (error) {
    dispatch(setError("Logout failed"));
    return false;
  }
};

// Add a function to fetch user data separately if needed
export const fetchUserData = () => async (dispatch, getState) => {
  const { token } = getState().user;
  if (!token) return false;

  dispatch(setLoading(true));
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/auth/me`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    dispatch(setUserDocument(response.data));
    dispatch(
      setUser({
        email: response.data.email,
        emailVerified: response.data.emailVerified,
        displayName: response.data.displayName,
        photoURL: response.data.photoURL,
      })
    );

    return true;
  } catch (error) {
    dispatch(
      setError(error.response?.data?.error || "Failed to fetch user data")
    );
    return false;
  }
};

export default userSlice.reducer;
