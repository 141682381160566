import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, userDocument } = useSelector((state) => state.user);
  const location = useLocation();

  console.log("User document:", userDocument);

  // Public routes that don't require authentication
  const publicRoutes = ["/login", "/register"];
  if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
    return <Navigate to="/login" />;
  }

  // If authenticated and signup is complete, redirect signup-related routes to dashboard
  if (isAuthenticated && userDocument?.signupStep === "done") {
    const signupRoutes = ["/pricing", "/shopify", "/signup-benefits"];

    if (signupRoutes.includes(location.pathname)) {
      return <Navigate to="/dashboard" />;
    }
  }

  // If authenticated but signup not complete, redirect based on step
  if (isAuthenticated && userDocument && userDocument.signupStep !== "done") {
    // Allow access to these routes during signup
    const signupRoutes = ["/pricing", "/shopify", "/signup-benefits"];

    if (signupRoutes.includes(location.pathname)) {
      return children;
    }

    if (userDocument.signupStep === 1) {
      return <Navigate to="/pricing" />;
    }

    if (userDocument.signupStep === 2) {
      return <Navigate to="/channels" />;
    }
  }

  return children;
};

export default ProtectedRoute;
