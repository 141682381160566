import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, H1, H3, NavButton } from "./styles/global/main.style";
import { PricingPlans } from "../data/pricing-data";
import {
  PricingContainer,
  PricingCard,
  PlanName,
  PlanPrice,
  MessagesLimit,
  FeaturesList,
  FeatureItem,
  PricingButton,
} from "./styles/Pricing.styled";
import { motion } from "framer-motion";
import {
  listVariants,
  itemVariants,
} from "./styles/global/framer-motion-variants";
import checkMark from "../assets/images/check-mark.svg";
import { api } from "../services/authService";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, logout } from "../store/slices/userSlice";

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [processingPlan, setProcessingPlan] = useState(null);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handlePackageSelect = async (plan) => {
    setLoading(true);
    setProcessingPlan(plan.id);
    setError("");

    try {
      const stripeId = plan.stripeId;

      // Log Stripe configuration
      console.log("Stripe Checkout Configuration:", {
        planId: plan.id,
        planName: plan.name,
        planPrice: plan.price,
        stripeId,
        environment: process.env.NODE_ENV,
        apiUrl: process.env.REACT_APP_API_URL,
      });

      const response = await api.post("/api/create-checkout-session", {
        priceId: stripeId,
        planLevel: PricingPlans.findIndex((p) => p.id === plan.id),
        planName: plan.name.toLowerCase(),
      });

      console.log("Stripe Checkout Response:", {
        success: true,
        sessionUrl: response.data.sessionUrl,
        sessionId: response.data.sessionId,
        stripeCustomerId: response.data.stripeCustomerId,
      });

      if (response.data.sessionUrl) {
        // Store session ID along with package info
        localStorage.setItem(
          "selectedPackage",
          JSON.stringify({
            ...plan,
            sessionId: response.data.sessionId,
          })
        );
        window.location.href = response.data.sessionUrl;
      }
    } catch (error) {
      console.error("Error details:", error.response?.data);
      const errorMessage =
        error.response?.data?.error ||
        "Failed to process payment. Please try again.";
      setError(errorMessage);
    } finally {
      setLoading(false);
      setProcessingPlan(null);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <MainContainer $isSignUp>
      <div id="header">
        <div className="header-text">
          <H1>Simple, transparent pricing</H1>
          <H3>Choose the plan that's right for your business</H3>
        </div>
      </div>

      {error && (
        <div
          style={{ color: "red", textAlign: "center", marginBottom: "20px" }}
        >
          {error}
        </div>
      )}

      <PricingContainer
        as={motion.div}
        variants={listVariants}
        initial="hidden"
        animate="visible"
      >
        {PricingPlans.map((plan) => (
          <PricingCard
            key={plan.id}
            as={motion.div}
            variants={itemVariants}
            $isPopular={plan.id === "grow"}
          >
            <PlanName>{plan.name}</PlanName>
            <PlanPrice>
              <span className="currency">$</span>
              <span className="amount">{plan.price}</span>
              <span className="period">/month</span>
            </PlanPrice>

            <MessagesLimit>
              {plan.messages.toLocaleString()} AI Messages/month
            </MessagesLimit>

            <FeaturesList>
              {plan.features.map((feature, featureIndex) => (
                <FeatureItem
                  key={featureIndex}
                  $isComingSoon={feature.includes("Coming Soon")}
                >
                  <img src={checkMark} alt="check-mark" />
                  {feature.replace(" (Coming Soon)", "")}
                </FeatureItem>
              ))}
            </FeaturesList>

            <PricingButton
              $isPopular={plan.id === "grow"}
              onClick={() => handlePackageSelect(plan)}
              disabled={loading}
            >
              {loading && processingPlan === plan.id
                ? "Processing..."
                : "Start Free Trial"}
            </PricingButton>
          </PricingCard>
        ))}
      </PricingContainer>
    </MainContainer>
  );
};

export default Pricing;
