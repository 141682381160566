const theme = {
  color: {
    primary: "#0385FF",
    primaryLight: "#0099FF",
    secondary: "#525460",
    success: "#10B981",
    danger: "#EF4444",
    warning: "#F59E0B",
    info: "#3B82F6",
    light: "#F9FAFB",
    dark: "#111827",
    white: "#FFFFFF",
    black: "#000000",
    gray: {
      100: "#F9FAFB",
      200: "#F3F4F6",
      300: "#E8EAEF",
      400: "#D1D5DB",
      500: "#9CA3AF",
      600: "#6B7280",
      700: "#4B5563",
      800: "#374151",
      900: "#1F2937",
    },
  },

  spacing: {
    xs: "0.5rem",
    sm: "1rem",
    md: "1.5rem",
    lg: "2rem",
    xl: "2.5rem",
    xxl: "3rem",
  },

  font: {
    fontFamilyPrimary: "'Poppins', -apple-system, sans-serif",
    fontFamilySecondary: "'SF Pro Display', -apple-system, sans-serif",
    fontFamilyMundial: "'SF Pro Text', -apple-system, sans-serif",

    size: {
      xs: "12px",
      sm: "14px",
      base: "16px",
      lg: "20px",
      xl: "28px",
      "2xl": "32px",
      "3xl": "28px",
    },

    weight: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },

  border: {
    radius: "2rem",
    radiusSmall: "1.5rem",
    width: "1px",
    widthMedium: "2px",
    widthLarge: "3px",
  },

  shadow: {
    small: "0 1px 2px rgba(0, 0, 0, 0.05)",
    medium:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    large:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  },

  breakpoints: {
    mobile: "320px",
    tablet: "640px",
    laptop: "1024px",
    desktop: "1280px",
  },

  transition: {
    default: "150ms cubic-bezier(0.4, 0, 0.2, 1)",
    fast: "100ms cubic-bezier(0.4, 0, 0.2, 1)",
    slow: "300ms cubic-bezier(0.4, 0, 0.2, 1)",
  },

  zIndex: {
    modal: 1000,
    overlay: 900,
    dropdown: 800,
    header: 700,
    footer: 600,
  },

  input: {
    height: "3.5rem",
    padding: "0 1.5rem",
    fontSize: "1rem",
    borderRadius: "2rem",
    borderColor: "#E5E7EB",
  },
};

export default theme;
