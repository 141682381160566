import styled from "styled-components";
import theme from "./global/theme";

// export const Container = styled.div`
//   padding: 40px;
//   max-width: 1200px;
//   margin: 0 auto;
// `;

// export const Header = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   margin-bottom: 40px;
// `;

// export const Logo = styled.img`
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
// `;

// export const Section = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 40px;
// `;

// export const FormGroup = styled.div`
//   margin-bottom: 20px;
// `;

// export const Label = styled.label`
//   color: ${theme.color.black};
//   font-family: Poppins;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 120%; /* 21.6px */
// `;

// export const Input = styled.input`
//   width: 100%;
//   padding: 8px;
//   border: 1px solid ${theme.color.gray[300]};
//   border-radius: ${theme.border.radius};
//   font-family: ${theme.font.fontFamilyMundial};

//   &:focus {
//     outline: none;
//     border-color: ${theme.color.primary};
//   }
// `;

// export const Button = styled.button`
//   background: ${theme.color.primary};
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: ${theme.border.radius};
//   cursor: pointer;
//   font-family: ${theme.font.fontFamilyMundial};

//   &:hover {
//     background: ${theme.color.info};
//   }

//   &:disabled {
//     background: ${theme.color.gray[400]};
//     cursor: not-allowed;
//   }
// `;

export const ErrorMessage = styled.div`
  color: ${theme.color.danger};
  margin-top: 8px;
  font-size: 14px;
`;

export const SuccessMessage = styled.div`
  color: ${theme.color.success};
  margin-top: 8px;
  font-size: 14px;
`;

// export const ButtonGroup = styled.div`
//   display: flex;
//   gap: 1rem;
//   margin-bottom: 2rem;
// `;

export const MyAccountContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 28px 20px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  border-radius: 12px;
  background: ${theme.color.gray[100]};
`;

export const Header = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: ${theme.color.gray[300]};
  box-sizing: border-box;
  margin-bottom: 36px;

  .header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    h1 {
      color: ${theme.color.black};
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
    }

    h2,
    h4 {
      margin: 0;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 22px;
`;

export const FormsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  width: 100%;
`;

export const FormGroup = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 20px;

  label {
    align-self: flex-start;
    text-align: left;
    width: 100%;
    color: ${theme.color.black};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
  }

  input {
    display: flex;
    box-sizing: border-box;
    height: 58px;
    padding: 0px 18px;
    align-items: center;
    border-radius: 8px;
    background: ${theme.color.gray[300]};
    width: 100%;
    border: none;
    color: ${theme.color.gray[500]};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:focus {
      outline: none;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;
