import styled, { css } from "styled-components";
import theme from "../../pages/styles/global/theme";

// Main sidebar container
export const SidebarContainer = styled.div`
  width: 250px;
  height: 100vh;
  background-color: ${theme.color.white};
  padding: 24px 16px;
  border-right: 1px solid ${theme.color.gray[200]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid #e2e2e2;
  background: #f3f4f6;
`;

// Logo/Header section
export const SidebarHeader = styled.div`
  padding: 0 8px 24px 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.color.gray[200]};

  h1 {
    font-family: ${theme.font.fontFamilyPrimary};
    font-size: ${theme.font.size.xl};
    font-weight: ${theme.font.weight.semibold};
    color: ${theme.color.dark};
  }
`;

// Navigation item
export const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: ${theme.border.radiusSmall};

  ${({ $isActive }) => css`
    background: ${$isActive ? theme.color.primary : "transparent"};

    img {
      width: 20px;
      height: 20px;
      opacity: ${$isActive ? 1 : 0.7};
      filter: ${$isActive ? "brightness(0) invert(1)" : "none"};
    }

    &:hover {
      background: ${$isActive ? theme.color.primary : theme.color.gray[200]};
      img {
        opacity: 1;
      }
    }
  `}
`;

// Nav item text
export const NavText = styled.span`
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${({ $isActive }) => css`
    color: ${$isActive ? theme.color.white : theme.color.gray[600]};
  `}
`;
