import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../services/authService";
import { setUser, setToken, setProfile } from "../../store/slices/userSlice";
import {
  AuthContainer,
  AuthForm,
  AuthInput,
  AuthButton,
  AuthLink,
  ErrorMessage,
  AuthImageContainer,
  AuthFormContainer,
  PhoneInputHint,
  PhoneInputGroup,
  AreaCodeSelect,
} from "../styles/Auth.styled";
import loginImage from "../../assets/images/ecombotcircle.png";
import eyeSlash from "../../assets/images/eye-slash.svg";
const areaCodes = [
  "+1", // USA/Canada
  "+44", // UK
  "+61", // Australia
  "+86", // China
  "+91", // India
  "+52", // Mexico
  "+81", // Japan
  "+49", // Germany
  "+33", // France
  "+34", // Spain
  "+39", // Italy
  "+7", // Russia
  "+55", // Brazil
  "+82", // South Korea
  "+31", // Netherlands
  "+45", // Denmark
];

const ecommercePlatforms = [
  { value: "shopify", label: "Shopify" },
  { value: "woocommerce", label: "WooCommerce" },
  { value: "magento", label: "Magento" },
  { value: "bigcommerce", label: "BigCommerce" },
  { value: "other", label: "Other" },
];

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    platform: "shopify", // Default platform
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [areaCode, setAreaCode] = useState("+1");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (
      !formData.phoneNumber ||
      formData.phoneNumber.replace(/\D/g, "").length !== 11
    ) {
      setError("Please enter a valid 10-digit phone number");
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      // Pass the phone number and platform
      const { user, token, profile } = await register(
        formData.email,
        formData.password,
        formData.phoneNumber,
        formData.platform
      );

      dispatch(setUser(user));
      dispatch(setToken(token));
      if (profile) {
        dispatch(setProfile(profile));
      }

      navigate("/pricing");
    } catch (error) {
      console.error("Registration error:", error);
      setError(error.message || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digits

    // Limit to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    // Format the number as (XXX) XXX-XXXX
    if (value.length > 0) {
      if (value.length <= 3) {
        value = `(${value}`;
      } else if (value.length <= 6) {
        value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
      } else {
        value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
      }
    }

    // Store with selected area code
    const formattedForStorage = value
      ? `${areaCode}${value.replace(/\D/g, "")}`
      : "";
    setFormData({ ...formData, phoneNumber: formattedForStorage });
  };

  // Add this function to display formatted number
  const getDisplayPhoneNumber = () => {
    const digits = formData.phoneNumber.replace(/\D/g, "").slice(1); // Remove +1 and non-digits
    if (digits.length === 0) return "";
    if (digits.length <= 3) return `(${digits}`;
    if (digits.length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  };

  return (
    <AuthContainer>
      <AuthFormContainer>
        <AuthForm onSubmit={handleSubmit}>
          <div className="header">
            <h2>Create your account</h2>
            <h3>Please enter your details for creating account.</h3>
          </div>
          <div className="form-inputs">
            <div className="form-input">
              <p>Email</p>
              <AuthInput
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-input">
              <p>Phone Number</p>
              <PhoneInputGroup>
                <AreaCodeSelect
                  value={areaCode}
                  onChange={(e) => setAreaCode(e.target.value)}
                >
                  {areaCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </AreaCodeSelect>
                <AuthInput
                  type="tel"
                  placeholder="(555) 555-5555"
                  value={getDisplayPhoneNumber()}
                  onChange={handlePhoneNumberChange}
                  required
                  style={{ flex: 1 }}
                />
              </PhoneInputGroup>
              <PhoneInputHint>
                U.S. numbers only • Format: (555) 555-5555
              </PhoneInputHint>
            </div>
            <div className="form-input">
              <p>Ecommerce Platform</p>
              <AreaCodeSelect
                value={formData.platform}
                onChange={(e) =>
                  setFormData({ ...formData, platform: e.target.value })
                }
                style={{ width: "100%" }}
              >
                {ecommercePlatforms.map((platform) => (
                  <option key={platform.value} value={platform.value}>
                    {platform.label}
                  </option>
                ))}
              </AreaCodeSelect>
            </div>
            <div className="form-input">
              <p>Password</p>
              <div style={{ position: "relative" }}>
                <AuthInput
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? "👁️" : <img src={eyeSlash} />}
                </button>
              </div>
            </div>
            <div className="form-input">
              <p>Confirm Password</p>
              <div style={{ position: "relative" }}>
                <AuthInput
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showConfirmPassword ? "👁️" : <img src={eyeSlash} />}
                </button>
              </div>
            </div>
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <AuthButton type="submit" disabled={loading}>
            {loading ? "Creating Account..." : "Register"}
          </AuthButton>
          <AuthLink to="/login">
            Already have an account?&nbsp;&nbsp;<span>Login</span>
          </AuthLink>
        </AuthForm>
      </AuthFormContainer>
      <AuthImageContainer>
        <img src={loginImage} alt="Login" />
      </AuthImageContainer>
    </AuthContainer>
  );
};

export default Register;
