import styled from "styled-components";
import { NavButton } from "./global/main.style";
import theme from "./global/theme";
export const SignupBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  position: relative;
  overflow: hidden;

  .signup-benefits-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: #95bf47;
    }
  }

  .ecommerce-mascot {
    position: absolute;
  }

  #ecommerce-mascot1 {
    top: 0px;
    right: -150px;
    rotate: 270deg;
    width: 300px;
    height: 300px;
  }

  #ecommerce-mascot2 {
    width: 220px;
    height: 220px;
    left: -90px;
    bottom: -100px;
    rotate: 20deg;
  }

  #ecommerce-mascot3 {
    right: -160px;
    bottom: -210px;
    rotate: -40deg;
  }

  .signup-benefits-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 48px;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  #future-of-ecommerce {
    color: ${theme.color.primary};
  }
`;

export const SignupBenefitsMiddleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 50px;
  min-width: 910px;
`;

export const SignupBenefitsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SignupBenefitsItem = styled.li`
  font-size: 16px;
  line-height: 1.6;
`;

export const SignupBenefitsButton = styled(NavButton)`
  background-color: #ffb347;
  color: white;
  width: 400px;
  height: 50px;

  &:hover {
    background-color: ${theme.color.primary};
  }
`;
