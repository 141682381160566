import React, { useEffect } from "react";
import { Routes, Route, Navigate, Link, useNavigate } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./store/store";
import ProtectedRoute from "./components/ProtectedRoute";
import { verifyAuth } from "./utils/auth";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "./components/Sidebar";
import { MainContainer } from "./pages/styles/global/main.style";
import ShopifyLogin from "./pages/auth/ShopifyLogin";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/Dashboard";
import Pricing from "./pages/Pricing";
import ConnectChannels from "./pages/ConnectChannels";
import MyAccount from "./pages/MyAccount";
import Inbox from "./pages/inbox/Inbox";
import { auth } from "./config/firebase";
import {
  setToken,
  setUser,
  clearUser,
  setUserDocument,
} from "./store/slices/userSlice";
import { api } from "./services/authService";
import InboxSettings from "./pages/inbox/Settings";
import InboxTraining from "./pages/inbox/Training";
import Stats from "./pages/social-manager/Stats";
import Posts from "./pages/social-manager/Posts";
import GeneratePost from "./pages/social-manager/GeneratePost";
import Contacts from "./pages/Contacts";
import Tasks from "./pages/Tasks";
import ComingSoon from "./pages/ComingSoon";
import EditCreative from "./pages/social-manager/EditCreative";
import Editor from "./pages/social-manager/EditCreative";
import Help from "./pages/Help";
import axios from "axios";
import SignupBenefits from "./pages/SignupBenefits";

// Telegram bot configuration
const TELEGRAM_BOT_TOKEN = "7586642781:AAG9PqtiLCYYbAzKA6CVeCvHJ7nuDHBGx9c";
// NOTE: To get the correct chat ID:
// 1. Start a chat with the bot at t.me/ecommercebotnotifs_bot
// 2. Send a message to the bot (e.g. "/start")
// 3. Visit https://api.telegram.org/bot7586642781:AAG9PqtiLCYYbAzKA6CVeCvHJ7nuDHBGx9c/getUpdates
// 4. Look for the "chat" object in the response and use that "id" value
const TELEGRAM_CHAT_ID = "7504813204"; // This needs to be updated with the correct chat ID

// Function to check if Telegram bot is properly initialized
const checkTelegramBot = async () => {
  try {
    const response = await axios.get(
      `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getUpdates`
    );
    console.log("Telegram Updates:", response.data);
    return response.data.ok;
  } catch (error) {
    console.error("Error checking Telegram bot:", error);
    return false;
  }
};

// Function to send error reports to Telegram
const sendErrorToTelegram = async (error, userInfo, page) => {
  // First check if bot is initialized
  const isBotInitialized = await checkTelegramBot();
  if (!isBotInitialized) {
    console.error("Telegram bot is not properly initialized");
    return;
  }

  // Get additional user info from Redux store
  const state = store.getState();
  const userDoc = state.user?.userDocument || {};

  // Safely format timestamp
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Unknown";
    try {
      // Handle Firestore timestamp
      if (timestamp.seconds) {
        return new Date(timestamp.seconds * 1000).toISOString();
      }
      // Handle regular date object or string
      return new Date(timestamp).toISOString();
    } catch (e) {
      return "Invalid Date";
    }
  };

  const message = `
🚨 Error Report
------------------
📅 Date: ${new Date().toISOString()}
📍 Page: ${page}

👤 User Details:
• ID: ${userInfo?.userId || "Unknown"}
• Email: ${userDoc.email || "Unknown"}
• Name: ${userDoc.displayName || "Unknown"}
• Company: ${userDoc.companyName || "Unknown"}
• Plan: ${userDoc.plan || "Unknown"}
• Signup Date: ${formatTimestamp(userDoc.createdAt)}

🌐 Technical Info:
• Path: ${userInfo?.path || "Unknown"}
• Browser: ${userInfo?.userAgent || "Unknown"}
• Platform: ${navigator.platform || "Unknown"}
• Screen: ${window.screen.width}x${window.screen.height}

❌ Error Details:
• Message: ${error?.message || "Unknown error"}
• Stack: ${error?.stack || "No stack trace"}
`;

  try {
    if (process.env.NODE_ENV === "development") {
      console.log("Error message:", message);
    } else {
      const response = await axios.post(
        `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`,
        {
          chat_id: TELEGRAM_CHAT_ID,
          text: message,
          parse_mode: "HTML",
          disable_web_page_preview: true,
        }
      );

      if (!response.data.ok) {
        console.error("Failed to send error to Telegram:", response.data);
      }
    }
  } catch (telegramError) {
    console.error(
      "Failed to send error to Telegram:",
      telegramError?.response?.data || telegramError
    );
  }
};

// 📍 Page: ${page}
// 👤 User Info: ${JSON.stringify(userInfo)}
// ❌ Error: ${error.message}
// 🔍 Stack: ${error.stack}

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Get user info from Redux store
    const state = store.getState();
    const userInfo = {
      userId: state.user?.userDocument?.uid,
      path: window.location.pathname,
      userAgent: navigator.userAgent,
    };

    // Send error to Telegram
    sendErrorToTelegram(error, userInfo, window.location.pathname);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <h1>Something went wrong</h1>
          <p>We've been notified and will fix this as soon as possible.</p>
          <button
            onClick={() => window.location.reload()}
            style={{
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Global error handler for unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
  const state = store.getState();
  const userInfo = {
    userId: state.user?.userDocument?.uid,
    path: window.location.pathname,
    userAgent: navigator.userAgent,
  };

  sendErrorToTelegram(event.reason, userInfo, "Unhandled Promise Rejection");
});

// Global error handler for uncaught exceptions
window.addEventListener("error", (event) => {
  const state = store.getState();
  const userInfo = {
    userId: state.user?.userDocument?.uid,
    path: window.location.pathname,
    userAgent: navigator.userAgent,
  };

  sendErrorToTelegram(event.error, userInfo, "Uncaught Exception");
});

const AppContent = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, userDocument } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const initAuth = async () => {
      await verifyAuth(dispatch);
    };
    initAuth();
  }, [dispatch]);

  useEffect(() => {
    // Auth state listener
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        dispatch(setToken(token));

        try {
          const response = await api.get("/api/auth/me");
          dispatch(setUserDocument(response.data));
          dispatch(
            setUser({
              email: response.data.email,
              emailVerified: response.data.emailVerified,
              displayName: response.data.displayName,
              photoURL: response.data.photoURL,
            })
          );
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch(clearUser());
        }
      } else {
        dispatch(clearUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <div className="App">
      {isAuthenticated && userDocument?.signupStep === "done" && <Sidebar />}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Navigate to="/dashboard" replace />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/pricing"
          element={
            <ProtectedRoute>
              <Pricing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/channels"
          element={
            <ProtectedRoute>
              <ConnectChannels />
            </ProtectedRoute>
          }
        />
        <Route
          path="/shopify"
          element={
            <ProtectedRoute>
              <ShopifyLogin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup-benefits"
          element={
            <ProtectedRoute>
              <SignupBenefits />
            </ProtectedRoute>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox/*"
          element={
            <ProtectedRoute>
              <Routes>
                <Route path="/" element={<Inbox />} />
                <Route path="training" element={<InboxTraining />} />
                <Route path="settings" element={<InboxSettings />} />
              </Routes>
            </ProtectedRoute>
          }
        />
        <Route
          path="/tasks"
          element={
            <ProtectedRoute>
              <Tasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/social-manager/*"
          element={
            <ProtectedRoute>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/social-manager/stats" replace />}
                />
                <Route path="stats" element={<Stats />} />
                <Route path="posts" element={<Posts />} />
                <Route path="generate" element={<GeneratePost />} />
              </Routes>
            </ProtectedRoute>
          }
        />
        <Route
          path="/advertising"
          element={
            <ProtectedRoute>
              <ComingSoon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contacts"
          element={
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <MyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editor"
          element={
            <ProtectedRoute>
              <Editor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/help"
          element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          }
        />

        {/* Catch-all route - redirect any unknown paths to dashboard */}
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Navigate to="/dashboard" replace />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </Provider>
  );
}

// Placeholder components
const SocialManager = () => <div>Social Manager Page</div>;
const Advertise = () => <div>Advertise Page</div>;
const ContactManager = () => <div>Contact Manager Page</div>;

export default App;
