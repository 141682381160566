import styled, { css } from "styled-components";

import theme from "./global/theme";

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 28px 20px;
  border-radius: 12px;
  background: #f3f4f6;
  width: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.$isContacts &&
    css`
      gap: 20px;
      height: fit-content;
    `}

  .pagination {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 30px;
  }
`;

export const PostBlock = styled.div`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #e8eaef;
  width: 100%;

  .engagement-metrics {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: ${theme.color.gray[900]};
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .post-metrics {
    display: flex;
    gap: 8px;

    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const PostImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: cover;
`;

export const PostLink = styled.a`
  text-decoration: none;
  color: ${theme.color.primary};
`;
