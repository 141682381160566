import styled, { css } from "styled-components";
import theme from "../styles/global/theme";
import { motion } from "framer-motion";

export const MessagesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  padding: 20px;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ChannelList = styled.div`
  background: white;
  border-right: 1px solid ${theme.color.gray[200]};
  padding: 20px;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 44px;
  height: 44px;
  padding: 8px 16px;
  background: ${theme.color.gray[300]};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${theme.color.gray[100]};
  }
`;

export const FilterMenuButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: ${theme.font.fontFamilyPrimary};
  transition: background 0.2s;
  &:hover {
    background: ${theme.color.gray[100]};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 44px;
  margin-bottom: 20px;
`;

export const FilterMenu = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  background: ${theme.color.gray[300]};
  border-radius: 8px;
  padding: 12px;
`;

export const ChatSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 16px;
  background: #f3f4f6;
  width: 49%;
`;

export const ChatHeader = styled.div`
  border-bottom: 1px solid ${theme.color.gray[300]};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
`;

export const MessageList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 25%;
  padding: 20px;
  overflow-y: auto;
  border-radius: 14px;
  background: #f3f4f6;
  gap: 12px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const MessageItem = styled(motion.div)`
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: ${theme.color.gray[300]};

  color: ${theme.color.dark};
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.$hasUnread ? "600" : "400")};
  line-height: normal;
  transition: all 0.2s ease;
  background-color: ${(props) =>
    props.$hasUnread ? "white" : theme.color.gray[300]};

  box-shadow: ${(props) =>
    props.$hasUnread ? "0px 0px 0px 2px " + theme.color.primary : "none"};

  #timestamp {
    font-size: 12px;
    color: #666;
  }

  #channel-icon-text {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
  }

  ${(props) =>
    props.$isSelected &&
    css`
      background: ${theme.color.gray[100]};
      box-shadow: 0px 0px 0px 1px ${theme.color.gray[400]};
      scale: 1.02;
    `}

  &:hover {
    background-color: ${(props) =>
      props.$hasUnread ? "rgba(0, 0, 0, 0.08)" : "rgba(0, 0, 0, 0.03)"};
  }
`;

export const MessageChannelIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 24px;
    height: 24px;
  }
`;

export const Message = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$isUser ? "flex-end" : "flex-start")};
  margin-bottom: 16px;
`;

export const MessageBubble = styled.div`
  background: ${(props) =>
    props.$isUser ? theme.color.primary : theme.color.gray[300]};
  color: ${(props) => (props.$isUser ? "white" : "black")};
  padding: 12px 16px;
  border-radius: 16px;
  max-width: 70%;
`;

export const MessageTime = styled.span`
  font-size: 12px;
  color: ${theme.color.gray[500]};
  margin-top: 4px;
`;

export const ChatInput = styled.div`
  padding: 20px;
  border-top: 1px solid ${theme.color.gray[200]};
  display: flex;
  gap: 12px;
`;

export const Input = styled(motion.input)`
  border-radius: 12px;
  background: #e8eaef;
  display: flex;
  width: 100%;
  padding: 6px 6px 6px 16px;
  justify-content: space-between;
  align-items: center;
  border: none;
  transition: all 0.2s ease;
  color: ${theme.color.gray[700]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:focus {
    outline: none;
    scale: 1.01;
  }
`;

export const SendButton = styled(motion.button)`
  display: flex;
  height: 44px;
  padding: 10px 16px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: ${theme.color.primary};
  color: white;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export const InfoLabel = styled.span`
  color: #525460;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const InfoValue = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  background: #e8eaef;
  margin-top: 4px;
  height: 44px;
  box-sizing: border-box;
`;

export const UserInfo = styled.div`
  margin-bottom: 12px;
  width: 100%;
  box-sizing: border-box;
  label {
    display: block;
    font-size: 12px;
    color: ${theme.color.gray[500]};
    margin-bottom: 4px;
  }

  span {
    font-size: 14px;
    color: ${theme.color.gray[900]};
  }
`;

export const UserInfoSection = styled.div`
  display: flex;
  width: 25%;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 14px;
  background: #f3f4f6;
`;

export const ConversationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid ${theme.color.gray[200]};
  background: ${(props) => (props.selected ? theme.color.gray[100] : "white")};
  border-radius: 8px;
  &:hover {
    background: ${theme.color.gray[100]};
  }
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ConversationInfo = styled.div`
  flex: 1;
  margin-left: 12px;
  min-width: 0;
`;

export const Name = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

export const LastMessage = styled.div`
  color: ${theme.color.gray[600]};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UnreadBadge = styled.span`
  background-color: #007aff;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  margin-right: auto;
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${theme.color.gray[500]};
`;

export const Button = styled.button`
  padding: 12px 24px;
  margin: 12px;
  width: 100px;
  height: 40px;
  background: ${theme.color.primary};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${theme.color.gray[300]};
  appearance: none;
  background-color: white;
  width: 20px;
  height: 20px;

  &:checked {
    background-color: ${theme.color.primary};
    border-color: ${theme.color.primary};
    position: relative;

    &::after {
      content: "✓";
      position: absolute;
      color: white;
      font-size: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const FilterLabel = styled.span`
  color: ${theme.color.gray[700]};
  font-size: 14px;
`;
