import { useEffect, useRef, useState } from "react";
import CreativeEditorSDK from "@cesdk/cesdk-js";
import { MainContainer } from "../styles/global/main.style";
import { ExportButton, SaveButton } from "./EditCreative.style";

const config = {
  license: process.env.REACT_APP_IMGLY_API_KEY,
  userId: "guides-user",
  // Enable local uploads in Asset Library
  callbacks: { onUpload: "local" },
};

// Storage key for saving editor state
const EDITOR_STATE_KEY = "creative_editor_state";

export default function EditCreative() {
  const cesdk_container = useRef(null);
  const [cesdk, setCesdk] = useState(null);

  // Handle export of the creative as PNG
  const handleExport = async () => {
    if (!cesdk) {
      console.error("Editor instance not available");
      return;
    }

    try {
      console.log("Starting export...");
      const result = await cesdk.export({
        format: "image/png",
        quality: 1,
      });

      if (result && result.blobs && result.blobs.length > 0) {
        const blob = result.blobs[0];
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `creative_${new Date().getTime()}.png`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        console.log("Download completed");
      } else {
        console.error("No blob found in export result");
      }
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  // Handle saving the current editor state
  const handleSave = async () => {
    if (!cesdk) {
      console.error("Editor instance not available");
      return;
    }

    try {
      console.log("Saving editor state...");
      const serializedState = await cesdk.engine.scene.saveToString();
      localStorage.setItem(EDITOR_STATE_KEY, serializedState);
      console.log("Editor state saved successfully");
    } catch (error) {
      console.error("Failed to save editor state:", error);
    }
  };

  // Load saved state when editor is initialized
  const loadSavedState = async (instance) => {
    try {
      const savedState = localStorage.getItem(EDITOR_STATE_KEY);
      if (savedState) {
        await instance.engine.scene.loadFromString(savedState);
        console.log("Loaded saved editor state");
      } else {
        // If no saved state exists, create a new design scene
        await instance.createDesignScene();
        console.log("Created new design scene");
      }
    } catch (error) {
      console.error("Failed to load saved state:", error);
      // Fallback to creating new design scene
      await instance.createDesignScene();
    }
  };

  useEffect(() => {
    if (!cesdk_container.current) return;

    let cleanedUp = false;
    let instance;
    CreativeEditorSDK.create(cesdk_container.current, config).then(
      async (_instance) => {
        instance = _instance;
        if (cleanedUp) {
          instance.dispose();
          return;
        }

        // Initialize the editor with default assets
        await Promise.all([
          instance.addDefaultAssetSources(),
          instance.addDemoAssetSources({ sceneMode: "Design" }),
        ]);

        // Load saved state or create new design
        await loadSavedState(instance);

        setCesdk(instance);
      }
    );
    const cleanup = () => {
      cleanedUp = true;
      instance?.dispose();
      setCesdk(null);
    };
    return cleanup;
  }, [cesdk_container]);

  return (
    <MainContainer>
      <div
        ref={cesdk_container}
        style={{ width: "80vw", height: "80vh" }}
      ></div>
      <SaveButton onClick={handleSave}>Save</SaveButton>
      <ExportButton onClick={handleExport}>Export</ExportButton>
    </MainContainer>
  );
}
