import { api } from "../services/authService";
import {
  setUser,
  setUserDocument,
  clearUser,
  setLoading,
  setToken,
} from "../store/slices/userSlice";
import { auth } from "../config/firebase";

export const serializeUser = (firebaseUser) => {
  if (!firebaseUser) return null;

  return {
    uid: firebaseUser.uid,
    email: firebaseUser.email,
    emailVerified: firebaseUser.emailVerified,
    displayName: firebaseUser.displayName,
    photoURL: firebaseUser.photoURL,
    phoneNumber: firebaseUser.phoneNumber,
  };
};

export const verifyAuth = async (dispatch) => {
  const token = localStorage.getItem("token");

  if (!token) {
    dispatch(setLoading(false));
    return false;
  }

  try {
    dispatch(setLoading(true));

    // Get user data from backend
    const response = await api.get("/api/auth/me");

    // Update Redux store with complete user data
    dispatch(setUserDocument(response.data));
    dispatch(
      setUser({
        email: response.data.email,
        emailVerified: response.data.emailVerified,
        displayName: response.data.displayName,
        photoURL: response.data.photoURL,
      })
    );

    return true;
  } catch (error) {
    console.error("Auth verification failed:", error);
    dispatch(clearUser());
    return false;
  } finally {
    dispatch(setLoading(false));
  }
};
