import styled from "styled-components";
import theme from "./global/theme";
import { motion } from "framer-motion";

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 100%;

  height: fit-content;
  font-family: ${theme.font.fontFamilyPrimary};
`;

export const PricingCard = styled(motion.div)`
  background: ${theme.color.white};
  border-radius: 24px;
  padding: 32px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: 30%;
  max-width: 400px;
  scale: 0.9;

  ${({ $isPopular }) =>
    $isPopular &&
    `
    border: 2px solid ${theme.color.primary};
    transform: scale(1.02);
    
    &:before {
      content: 'Most Popular';
      position: absolute;
      top: 30px;
      right: -40px;
      background: ${theme.color.primary};
      color: white;
      padding: 8px 40px;
      transform: rotate(45deg);
      font-size: 14px;
      font-weight: 500;
    }
  `}
`;

export const PlanName = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${theme.color.black};
  margin: 0;
`;

export const PlanPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 4px;

  span.currency {
    font-size: 24px;
    font-weight: 500;
    color: ${theme.color.black};
  }

  span.amount {
    font-size: 48px;
    font-weight: 700;
    color: ${theme.color.black};
  }

  span.period {
    font-size: 16px;
    color: ${theme.color.gray[600]};
    margin-left: 4px;
  }
`;

export const MessagesLimit = styled.div`
  background: ${theme.color.gray[100]};
  padding: 12px;
  border-radius: 12px;
  text-align: center;
  font-size: 16px;
  color: ${theme.color.gray[700]};
  font-weight: 500;
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${theme.color.gray[700]};
  font-size: 16px;

  svg {
    color: ${theme.color.primary};
    font-size: 20px;
  }

  ${({ $isComingSoon }) =>
    $isComingSoon &&
    `
    opacity: 0.7;
    
    &:after {
      content: '(Coming Soon)';
      font-size: 14px;
      color: ${theme.color.primary};
      margin-left: 8px;
    }
  `}
`;

export const PricingButton = styled.button`
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: none;
  background: ${({ $isPopular }) =>
    $isPopular ? theme.color.primary : theme.color.gray[100]};
  color: ${({ $isPopular }) =>
    $isPopular ? theme.color.white : theme.color.black};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ $isPopular }) =>
      $isPopular ? theme.color.primaryLight : theme.color.gray[200]};
  }
`;
