import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  writeBatch,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import {
  ChatSection,
  ChatHeader,
  MessageList,
  Message,
  MessageBubble,
  MessageTime,
  ChatInput,
  Input,
  SendButton,
  UserInfo,
  UserInfoSection,
  Avatar,
  Name,
  EmptyState,
  MessageItem,
  MessageChannelIcon,
  MessagesContainer,
  InfoLabel,
  InfoValue,
  FilterButton,
  FilterContainer,
  FilterMenu,
  FilterMenuButton,
  Checkbox,
  FilterLabel,
} from "../styles/Inbox.styled";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import widget from "../../assets/images/livechat.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import sendIcon from "../../assets/images/send.svg";
import filterIcon from "../../assets/images/filter-icon.svg";
import defaultAvatar from "../../assets/images/profile-user.svg";
import { H1, H2, MainContainer, NavButton } from "../styles/global/main.style";
import {
  listVariants,
  itemVariants,
  filterMenuVariants,
} from "../styles/global/framer-motion-variants";
import { AnimatePresence } from "framer-motion";

const Inbox = () => {
  const { userDocument } = useSelector((state) => state.user);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messageInput, setMessageInput] = useState("");
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState(["all"]);

  const userInfo = [
    { Label: "Name", value: selectedConversation?.customerInfo?.name },
    { Label: "Email", value: selectedConversation?.customerInfo?.email },
    { Label: "Phone", value: selectedConversation?.customerInfo?.phone },
  ];

  const channelIcons = {
    widget: widget,
    instagram: instagram,
    facebook: facebook,
  };

  const navigate = useNavigate();
  const location = useLocation();
  // Listen for conversations in real-time
  useEffect(() => {
    if (!userDocument?.uid) return;

    const q = query(
      collection(db, "conversations"),
      where("merchant_id", "==", userDocument.uid),
      where("status", "==", "active"),
      orderBy("updatedAt", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const conversationsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(conversationsData);

        // Set the most recent conversation as selected if none is selected
        if (!selectedConversation && conversationsData.length > 0) {
          setSelectedConversation(conversationsData[0]);
        }

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching conversations:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userDocument?.uid, selectedConversation]);

  // Create a function to handle conversation selection
  const handleConversationSelect = (conversation) => {
    // Clear messages immediately before switching
    setMessages([]);
    setSelectedConversation(conversation);
  };

  // Add this function to handle new messages in selected conversation
  const markNewMessageAsRead = async (newMessage, conversationRef) => {
    if (
      newMessage.senderId !== userDocument.uid && // Message is from other person
      newMessage.status === "unread" && // Message is unread
      newMessage.recipientId === userDocument.uid // Message is for current user
    ) {
      const batch = writeBatch(db);

      // Update message status to read
      const messageRef = doc(db, "messages", newMessage.id);
      batch.update(messageRef, { status: "read" });

      // Only update the unread count and seen status, preserve the existing lastMessage content
      batch.update(conversationRef, {
        "stats.unreadCount": 0,
        "lastMessage.seen": true,
      });

      await batch.commit().catch((error) => {
        console.error("Error marking new message as read:", error);
      });
    }
  };

  // Update the messages useEffect
  useEffect(() => {
    if (!selectedConversation?.id) return;

    const q = query(
      collection(db, "messages"),
      where("conversationId", "==", selectedConversation.id),
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Get all messages including new ones
      const messagesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);

      // Handle new messages
      snapshot
        .docChanges()
        .filter((change) => change.type === "added")
        .forEach((change) => {
          const newMessage = { id: change.doc.id, ...change.doc.data() };
          const conversationRef = doc(
            db,
            "conversations",
            selectedConversation.id
          );
          markNewMessageAsRead(newMessage, conversationRef);
        });
    });

    return () => unsubscribe();
  }, [selectedConversation?.id, userDocument?.uid]);

  useEffect(() => {
    const messagesContainer = document.querySelector(".messages-container");
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]); // Scroll whenever messages update

  const handleSendMessage = async () => {
    if (!messageInput.trim() || !selectedConversation?.id) return;
    setMessageInput("");
    try {
      // Send message through API with manualResponse flag
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/messages/send`,
        {
          conversationId: selectedConversation.id,
          content: messageInput,
          manualResponse: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleChannelFilter = (channel) => {
    setSelectedChannels((prev) => {
      if (channel === "all") {
        return ["all"];
      }

      const newChannels = prev.filter((ch) => ch !== "all");
      if (prev.includes(channel)) {
        // Remove channel if already selected
        const filtered = newChannels.filter((ch) => ch !== channel);
        // If no channels selected, default to 'all'
        return filtered.length === 0 ? ["all"] : filtered;
      } else {
        // Add new channel
        return [...newChannels, channel];
      }
    });
  };

  // Add this function to handle marking messages as read
  const markConversationAsRead = async (conversationId) => {
    if (!userDocument?.uid || !conversationId) return;

    try {
      const batch = writeBatch(db);

      // Update conversation unread count
      const conversationRef = doc(db, "conversations", conversationId);
      batch.update(conversationRef, {
        "stats.unreadCount": 0,
        lastMessage: {
          ...selectedConversation?.lastMessage,
          seen: true,
        },
      });

      // Get unread messages for this conversation
      const unreadMessagesQuery = query(
        collection(db, "messages"),
        where("conversationId", "==", conversationId),
        where("status", "==", "unread"),
        where("recipientId", "==", userDocument.uid)
      );

      const unreadSnapshot = await getDocs(unreadMessagesQuery);

      // Mark each message as read
      unreadSnapshot.docs.forEach((doc) => {
        batch.update(doc.ref, { status: "read" });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error marking conversation as read:", error);
    }
  };

  // Add this useEffect to mark messages as read when conversation is selected
  useEffect(() => {
    if (selectedConversation?.id) {
      markConversationAsRead(selectedConversation.id);
    }
  }, [selectedConversation?.id]);

  // Render loading state
  if (loading) {
    return (
      <MainContainer>
        <div id="header">
          <H1>Training</H1>
          <div id="buttons">
            <NavButton onClick={() => navigate("/inbox/training")}>
              Inbox
            </NavButton>
            <NavButton onClick={() => navigate("/inbox/settings")}>
              Settings
            </NavButton>
            <NavButton onClick={() => navigate("/inbox")}>Inbox</NavButton>
          </div>
        </div>
        <EmptyState>Loading conversations...</EmptyState>
      </MainContainer>
    );
  }

  // Render empty state
  if (!loading && conversations.length === 0) {
    return (
      <MainContainer>
        <div id="header">
          <H1>Inbox</H1>
          <div id="buttons">
            <NavButton onClick={() => navigate("/inbox/training")}>
              Training
            </NavButton>
            <NavButton onClick={() => navigate("/inbox/settings")}>
              Settings
            </NavButton>
            <NavButton onClick={() => navigate("/inbox")}>Inbox</NavButton>
          </div>
        </div>
        <EmptyState>No conversations yet</EmptyState>
      </MainContainer>
    );
  }

  const filteredConversations = conversations.filter((conversation) => {
    if (selectedChannels.includes("all")) return true;
    return selectedChannels.includes(conversation.channelType);
  });

  return (
    <MainContainer $noScroll>
      <div id="header">
        <H1>Inbox</H1>
        <div id="buttons">
          <NavButton
            onClick={() => navigate("/inbox/training")}
            $isFilled={location.pathname === "/inbox/training"}
          >
            Training
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox/settings")}
            $isFilled={location.pathname === "/inbox/settings"}
          >
            Settings
          </NavButton>
          <NavButton
            onClick={() => navigate("/inbox")}
            $isFilled={location.pathname === "/inbox"}
          >
            Inbox
          </NavButton>
        </div>
      </div>

      <div id="sections-container">
        <MessageList variants={listVariants} initial="hidden" animate="visible">
          <FilterContainer>
            <H2>Conversations</H2>
            <FilterButton onClick={() => setFilterIsOpen(!filterIsOpen)}>
              <img src={filterIcon} alt="Filter Icon" />
            </FilterButton>
          </FilterContainer>
          {filterIsOpen && (
            <FilterMenu
              variants={filterMenuVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <FilterMenuButton onClick={() => setSelectedChannels(["all"])}>
                <Checkbox
                  type="checkbox"
                  checked={selectedChannels.includes("all")}
                  readOnly
                />
                <FilterLabel>All Channels</FilterLabel>
              </FilterMenuButton>

              <FilterMenuButton onClick={() => handleChannelFilter("widget")}>
                <Checkbox
                  type="checkbox"
                  checked={selectedChannels.includes("widget")}
                  readOnly
                />
                <FilterLabel>Widget</FilterLabel>
              </FilterMenuButton>

              <FilterMenuButton
                onClick={() => handleChannelFilter("instagram")}
              >
                <Checkbox
                  type="checkbox"
                  checked={selectedChannels.includes("instagram")}
                  readOnly
                />
                <FilterLabel>Instagram</FilterLabel>
              </FilterMenuButton>

              <FilterMenuButton onClick={() => handleChannelFilter("facebook")}>
                <Checkbox
                  type="checkbox"
                  checked={selectedChannels.includes("facebook")}
                  readOnly
                />
                <FilterLabel>Facebook</FilterLabel>
              </FilterMenuButton>
            </FilterMenu>
          )}
          {filteredConversations.map(
            (conversation) =>
              conversation.lastMessage && (
                <MessageItem
                  key={conversation.id}
                  variants={itemVariants}
                  $hasUnread={conversation.stats.unreadCount > 0}
                  layout
                  onClick={() => {
                    // Only handle click if this isn't the currently selected conversation
                    if (selectedConversation?.id !== conversation.id) {
                      handleConversationSelect(conversation);
                    }
                  }}
                  $isSelected={selectedConversation?.id === conversation.id}
                >
                  <div id="channel-icon-text">
                    <MessageChannelIcon>
                      <img
                        src={channelIcons[conversation.channelType] || widget}
                        alt={`${conversation.channelType} Icon`}
                      />
                    </MessageChannelIcon>

                    <div>
                      {conversation.lastMessage?.content
                        .split(" ")
                        .reduce((acc, word) => {
                          if (acc.length + word.length <= 25) {
                            return acc + (acc ? " " : "") + word;
                          }
                          return acc;
                        }, "")}
                      {conversation.lastMessage?.content.length > 25
                        ? "..."
                        : ""}
                    </div>
                  </div>
                  {/* Timestamp */}
                  <span id="timestamp">
                    {conversation.lastMessage?.timestamp
                      ?.toDate()
                      .toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </span>
                </MessageItem>
              )
          )}
        </MessageList>

        <ChatSection
          variants={listVariants}
          initial="hidden"
          animate="visible"
          layout
        >
          {selectedConversation && messages.length > 0 ? (
            <>
              <ChatHeader>
                {selectedConversation.customerInfo && (
                  <>
                    <Avatar
                      src={
                        selectedConversation.customerInfo.avatar ||
                        defaultAvatar
                      }
                      alt={selectedConversation.customerInfo.name || "Customer"}
                    />
                    <Name>
                      {selectedConversation.customerInfo.name ||
                        "Unknown Customer"}
                    </Name>
                  </>
                )}
              </ChatHeader>
              <AnimatePresence mode="wait">
                <MessagesContainer
                  className="messages-container"
                  key={selectedConversation.id}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={{
                    hidden: { opacity: 0 },
                    visible: {
                      opacity: 1,
                      transition: {
                        staggerChildren: 0.03,
                        duration: 0.1,
                      },
                    },
                    exit: {
                      opacity: 0,
                      transition: { duration: 0.1 },
                    },
                  }}
                >
                  {messages.map((message, index) => (
                    <Message
                      key={`${selectedConversation.id}-${message.id}`}
                      $isUser={message.senderId === userDocument.uid}
                      variants={{
                        hidden: {
                          opacity: 0,
                          x: message.senderId === userDocument.uid ? 10 : -10,
                          scale: 0.95,
                        },
                        visible: {
                          opacity: 1,
                          x: 0,
                          scale: 1,
                          transition: {
                            type: "spring",
                            damping: 25,
                            stiffness: 500,
                            duration: 0.2,
                          },
                        },
                        exit: {
                          opacity: 0,
                          scale: 0.95,
                          transition: { duration: 0.1 },
                        },
                      }}
                    >
                      <MessageBubble
                        $isUser={message.senderId === userDocument.uid}
                      >
                        {message.content}
                      </MessageBubble>
                      <MessageTime>
                        {message.timestamp?.toDate().toLocaleTimeString()}
                      </MessageTime>
                    </Message>
                  ))}
                </MessagesContainer>
              </AnimatePresence>
              <ChatInput>
                <Input
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  placeholder="Enter your message here..."
                  onKeyDown={(e) => e.key === "Enter" && handleSendMessage(e)}
                  whileFocus={{ scale: 1.01 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                />
                <SendButton
                  onClick={handleSendMessage}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                >
                  Send <img src={sendIcon} alt="Send Icon" />
                </SendButton>
              </ChatInput>
            </>
          ) : (
            <EmptyState>
              {selectedConversation
                ? "Loading messages..."
                : "Select a conversation to start chatting"}
            </EmptyState>
          )}
        </ChatSection>
        <UserInfoSection>
          {userInfo.map((info) => (
            <UserInfo key={info.Label}>
              <InfoLabel>{info.Label}</InfoLabel>
              <InfoValue>{info.value}</InfoValue>
            </UserInfo>
          ))}
        </UserInfoSection>
      </div>
    </MainContainer>
  );
};

export default Inbox;
