import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import homeIcon from "../assets/images/home-2.svg";
import inboxIcon from "../assets/images/sms.svg";
import socialIcon from "../assets/images/Discord.svg";
import tasksIcon from "../assets/images/document.svg";
import contactsIcon from "../assets/images/call-calling.svg";
import advertisingIcon from "../assets/images/paperclip.svg";
import calendarIcon from "../assets/images/calendar.svg";
import profileIcon from "../assets/images/profile.svg";
import walletIcon from "../assets/images/wallet-2.svg";
import helpIcon from "../assets/images/info-circle.svg";
import channelsIcon from "../assets/images/add-square.svg";
import editorIcon from "../assets/images/editorIcon.svg";
import {
  SidebarContainer,
  SidebarHeader,
  NavItem,
  NavText,
} from "./styles/Sidebar.styled";
import axios from "axios";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isBlocked } = useSelector((state) => state.navigation);

  const handleNavigation = async (path) => {
    if (isBlocked) {
      return; // Prevent navigation while blocked
    }

    if (path === "/subscriptions") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/stripe/portal/create-portal-session`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.url) {
          window.open(response.data.url, "_blank");
          return;
        }
      } catch (error) {
        console.error("Failed to open subscription portal:", error);
        // Fallback to subscription page if portal fails
        navigate("/subscriptions");
      }
    } else {
      navigate(path);
    }
  };

  // Helper function to check if a path is active
  const isPathActive = (path) => {
    if (path === "/inbox") {
      return location.pathname.startsWith("/inbox");
    }

    if (path === "/social-manager/stats") {
      return location.pathname.startsWith("/social-manager");
    }

    return location.pathname === path;
  };

  const navItems = [
    { path: "/channels", label: "Connect Channel", icon: channelsIcon },
    { path: "/dashboard", label: "Home", icon: homeIcon },
    { path: "/inbox", label: "Inbox", icon: inboxIcon },
    {
      path: "/social-manager/stats",
      label: "Social Manager",
      icon: socialIcon,
    },
    { path: "/tasks", label: "Tasks", icon: tasksIcon },
    { path: "/contacts", label: "Contacts", icon: contactsIcon },
    { path: "/advertising", label: "Advertising", icon: advertisingIcon },
    { path: "/editor", label: "Editor", icon: editorIcon },
    { path: "/account", label: "My Account", icon: profileIcon },
    { path: "/subscriptions", label: "Subscriptions", icon: walletIcon },
    { path: "/help", label: "Help", icon: helpIcon },
  ];

  return (
    <SidebarContainer>
      <SidebarHeader>
        <h1>EcommerceBot</h1>
      </SidebarHeader>

      {navItems.map((item) => (
        <NavItem
          key={item.path}
          $isActive={isPathActive(item.path)}
          onClick={() => handleNavigation(item.path)}
          style={{ cursor: isBlocked ? "not-allowed" : "pointer" }}
        >
          <img src={item.icon} alt={item.label} />
          <NavText $isActive={isPathActive(item.path)}>{item.label}</NavText>
        </NavItem>
      ))}
    </SidebarContainer>
  );
};

export default Sidebar;
