import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  MainContainer,
  H1,
  H2,
  H3,
  NavButton,
} from "./styles/global/main.style";
import {
  MetricsContainer,
  MetricList,
  QuickLinksContainer,
  QuickLinkItem,
  TaskList,
} from "./styles/Dashboard.styled";
import styled from "styled-components";
import theme from "./styles/global/theme";

// Additional styled components specific to Help page
const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 16px;
  border: 1px solid ${theme.color.gray[300]};
  border-radius: 12px;
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 16px;
  resize: vertical;
  margin-top: 16px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }
`;

const HelpText = styled.p`
  color: ${theme.color.gray[600]};
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const FeedbackMessage = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
  font-family: ${theme.font.fontFamilyPrimary};
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $type }) => {
    switch ($type) {
      case "success":
        return `
          background-color: #ecfdf5;
          color: #047857;
          border: 1px solid #047857;
        `;
      case "error":
        return `
          background-color: #fef2f2;
          color: #dc2626;
          border: 1px solid #dc2626;
        `;
      default:
        return "";
    }
  }}
`;

const Help = () => {
  const [bugReport, setBugReport] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const { userDocument } = useSelector((state) => state.user);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!bugReport.trim()) {
      setFeedback({
        type: "error",
        message: "Please provide a description of the bug.",
      });
      return;
    }

    setIsSubmitting(true);
    setFeedback(null);

    try {
      // Send bug report to backend which will forward to Telegram
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report-bug`,
        {
          description: bugReport,
          userEmail: userDocument?.email,
          userId: userDocument?.uid,
          timestamp: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setBugReport("");
      setFeedback({
        type: "success",
        message:
          "Bug report submitted successfully! The development team has been notified and will investigate the issue.",
      });
    } catch (error) {
      console.error("Error submitting bug report:", error);
      setFeedback({
        type: "error",
        message:
          "Failed to submit bug report. Please try again or contact support directly.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const helpResources = [
    {
      id: 1,
      title: "Getting Started Guide",
      url: "/docs/getting-started",
      description: "Learn the basics of using the platform",
    },
    {
      id: 2,
      title: "Social Media Integration",
      url: "/docs/social-media",
      description: "Connect and manage social media accounts",
    },
    {
      id: 3,
      title: "Analytics & Reporting",
      url: "/docs/analytics",
      description: "Understand platform analytics and metrics",
    },
  ];

  const quickLinks = [
    {
      id: 1,
      title: "FAQ",
      url: "/faq",
    },
    {
      id: 2,
      title: "Video Tutorials",
      url: "/tutorials",
    },
    {
      id: 3,
      title: "Contact Support",
      url: "/support",
    },
  ];

  return (
    <MainContainer>
      <div id="header">
        <div className="header-text">
          <H1>Help Center</H1>
          <H3>Find answers and support for platform features</H3>
        </div>
      </div>

      <MetricsContainer>
        <TaskList>
          <div className="task-list-header">
            <H2 style={{ marginBottom: 0 }}>Report a Bug</H2>
          </div>
          <form onSubmit={handleSubmit}>
            <HelpText>
              Found something not working as expected? Let us know and we'll fix
              it as soon as possible.
            </HelpText>
            <TextArea
              value={bugReport}
              onChange={(e) => setBugReport(e.target.value)}
              placeholder="Please describe the bug in detail. Include what you were doing when it occurred and any error messages you saw."
              disabled={isSubmitting}
            />
            {feedback && (
              <FeedbackMessage $type={feedback.type}>
                {feedback.message}
              </FeedbackMessage>
            )}
            <NavButton
              type="submit"
              $isFilled
              style={{ marginTop: "16px" }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Report"}
            </NavButton>
          </form>
        </TaskList>
      </MetricsContainer>
    </MainContainer>
  );
};

export default Help;
