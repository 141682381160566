import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const StatsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ChannelsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 30px;
`;

export const CacheInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  small {
    color: #666;
  }

  button {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: #f5f5f5;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

// Loading skeleton components
export const SkeletonStatBlock = styled.div`
  width: calc(25% - 12px);
  min-width: 240px;
  height: 140px;
  border-radius: 16px;
  background: #f3f4f6;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1200px) {
    width: calc(33.33% - 11px);
  }

  @media (max-width: 900px) {
    width: calc(50% - 8px);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SkeletonChannelBlock = styled.div`
  width: calc(33.33% - 11px);
  height: 200px;
  border-radius: 16px;
  background: #f3f4f6;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 900px) {
    width: calc(50% - 8px);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SkeletonText = styled.div`
  height: ${(props) => props.$height || "16px"};
  width: ${(props) => props.$width || "60%"};
  border-radius: 4px;
  background: linear-gradient(to right, #e8eaef 8%, #f3f4f6 18%, #e8eaef 33%);
  background-size: 2000px 100%;
  animation: ${shimmer} 2s linear infinite;
`;

export const SkeletonValue = styled(SkeletonText)`
  width: ${(props) => props.$width || "80%"};
  height: 24px;
  margin-top: 4px;
`;
