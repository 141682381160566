import styled from "styled-components";
import theme from "../../pages/styles/global/theme";

export const ChatBotContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
`;

export const ChatButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }

  .chat-button-icon {
    width: 33px;
    height: 33px;
  }
`;

export const ChatWindow = styled.div`
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 340px;
  height: 420px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
`;

export const ChatHeader = styled.div`
  padding: 15px;
  background: ${theme.color.primary};
  color: white;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const ChatMessages = styled.div`
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  height: 280px;

  /* Hide scrollbar while keeping functionality */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;

export const Message = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: ${(props) => (props.isBot ? "#f0f0f0" : props.brandColor)};
  color: ${(props) => (props.isBot ? "inherit" : "white")};
  border-radius: 5px;
  margin-left: ${(props) => (props.isBot ? "0" : "auto")};
  border-radius: ${(props) =>
    props.isBot ? "10px 10px 10px 0px" : "10px 10px 0 10px"};
  max-width: 70%;
`;

export const InputContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  border: none;
  width: 100%;
  position: relative;
`;

export const Input = styled.input`
  border-radius: 5px;
  background: #e8eaef;
  border: none;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 44px;
  padding-right: 112px;

  &:focus {
    outline: none;
  }
`;

export const SendButton = styled.button`
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  display: flex;
  height: 44px;
  padding: 10px 16px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
`;
